<template>
    <v-list class="pa-0" dense>
        <v-list-item-group multiple>
            <v-list-item-content class="pt-0">
                <v-list-item-title>Event Type:</v-list-item-title>
            </v-list-item-content>
            <v-list-item class="pa-0" @click="toggle">
                <v-list-item-action>
                    <v-icon>{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>All Event Types</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item class="pa-0" v-for="item in eventtypes" :key="item">
                <v-list-item-action>
                    <v-checkbox v-model="isEventtypes" :value="item"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'eventtypes', 'searchparams' ] ),
        isEventtypes: {
            get() { return this.searchparams.eventtype },
            set(item) { this.upParamsEt(item) }
        },
        selAllEventTypes () {
            return this.searchparams.eventtype.length === this.eventtypes.length
        },
        selSomeEventTypes () {
            return this.searchparams.eventtype.length > 0 && !this.selAllEventTypes
        },
        icon () {
            if (this.selAllEventTypes) return 'mdi-close-box'
            if (this.selSomeEventTypes) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsEt' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllEventTypes) {
                this.searchparams.eventtype = []
            } else {
                this.searchparams.eventtype = this.eventtypes.slice()
            }
            })
        },
    }
}
</script>

<style>

</style>