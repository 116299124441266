<template>
    <v-select
        v-model="isGenres"
        :items="genres"
        label="Genre"
        append-icon="mdi-chevron-down"
        filled
        hide-details
    ></v-select>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'event', [ 'eventparams', 'genres' ] ),
        isGenres: {
            get() { return this.eventparams.genre },
            set(item) { this.upParamsGenre(item) }
        }
    },
    methods: {
        ...mapMutations( 'event', [ 'upParamsGenre' ] ),
    }
}
</script>

<style>

</style>