import axios from 'axios'

export const namespaced = true

export const state = {
    events: [],
    yrevents: [],
    years: [],
    inidtevents: [],
    findtevents: [],
    spevents: [],
    sports: [],
    coevents: [],
    coverages: [],
    hcevents: [],
    hostcountries: [],
    livestreams: [ 'Sport Orgs TV', 'Pay-Per-View', 'Social Media Live' ],
    sportorgs: [],
    //intfeds: [],
    eventtypes: [ 'Competition', 'Conference' ],
    seasons: [ 'Summer', 'Winter' ],
    olyplys: [ 'Olympic', 'Paralympic', 'Other'],
    genres: [ 'Men/Women', 'Men', 'Women'],
    tickets: [ 'Buy', 'Free', 'Closed'],
    searchparams: {
        txtsearch: '',
        year: [],
        inidate: new Date().toISOString().substr(0, 10),
        findate: '',
        sport: [],
        coverage: [],
        hostcountry: [],
        livestream: [],
        sportorg: [],
        //intfed: [],
        eventtype: [],
        season: [],
        olyply: [],
        genre: [],
        ticket: []
    },
}

export const getters = {
    searchEvents: state => state.events
}

export const mutations = {
    setEvents: (state, events) => (state.events = events),
    setYears: (state, years) => (state.years = years),
    //setYears: (state, years) => (state.searchparams.year = years),
    setSports: (state, sports) => (state.sports = sports),
    setCoverages: (state, coverages) => (state.coverages = coverages),
    setHostCountries: (state, hostcountries) => (state.hostcountries = hostcountries),
    setSportOrgs: (state, sportorgs) => (state.sportorgs = sportorgs),
    setIntFederations: (state, intfeds) => (state.intfeds = intfeds),

    upParamsTx: (state, uppartx) => (state.searchparams.txtsearch = uppartx),
    upParamsYr: (state, upparyr) => (state.searchparams.year = upparyr),
    upParamsId: (state, upparid) => (state.searchparams.inidate = upparid),
    upParamsFd: (state, upparfd) => (state.searchparams.findate = upparfd),
    upParamsSp: (state, upparsp) => (state.searchparams.sport = upparsp),
    upParamsHc: (state, upparhc) => (state.searchparams.hostcountry = upparhc),
    upParamsLs: (state, upparls) => (state.searchparams.livestream = upparls),
    upParamsCo: (state, upparco) => (state.searchparams.coverage = upparco),
    upParamsSo: (state, upparso) => (state.searchparams.sportorg = upparso),
    //upParamsIf: (state, upparif) => (state.searchparams.sportorg.concat(upparif)),
    upParamsEt: (state, upparet) => (state.searchparams.eventtype = upparet),
    upParamsSs: (state, upparss) => (state.searchparams.season = upparss),
    upParamsOp: (state, upparop) => (state.searchparams.olyply = upparop),
    upParamsGe: (state, upparge) => (state.searchparams.genre = upparge),
    upParamsTk: (state, uppartk) => (state.searchparams.ticket = uppartk),
}

export const actions = {
    // Search Events
    async fetchEvents( { commit }, parms) {      
        //console.log(JSON.stringify(parms))
        let response = await axios.get('/searchevent/search', { params: parms } )
        //console.log(response.config.url);
        //console.log(JSON.stringify(response.data))
        let arrevents = response.data
        commit('setEvents', arrevents)      
    },
    // Home Form Selects
    //async fetchYears( { commit }, accessToken ) {
    async fetchYears( { commit } ) {
        //const accessToken = this.$auth.getTokenSilently()
        //const accessToken = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik9sdWdjZEhncXc4ZGVPaXB3bFZGaiJ9.eyJpc3MiOiJodHRwczovL2Rldi1rNzFlcW94My5ldS5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NjA3ZGFlOWY0OWZkODQwMDczODVjNmY5IiwiYXVkIjpbImh0dHBzOi8vdnVlLWV4cHJlc3MtYXBpLmNvbSIsImh0dHBzOi8vZGV2LWs3MWVxb3gzLmV1LmF1dGgwLmNvbS91c2VyaW5mbyJdLCJpYXQiOjE2MjgxNDUwNjMsImV4cCI6MTYyODIzMTQ2MywiYXpwIjoiN0RvcGEyMlJTWXFkSlpxbWU1dXFtWDA0ZmI1YzI3eWMiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIn0.o6Xld2nbbDmKU4eZ3tbqyqHDCm1G7djd_ITgYpa4Wl9-37GnYahhpA8usxxwowOi9-TX3jVaFqF2k6N8tkg67cWR5SmlKFF0q-3nndUL1XrFIJx6eKmdCAeObQ0_GXuCJ5Bw3P3BJmmePjc3sHSE145dusvCbkbaRe-1jDt_r9F2Kpm7dcz5fydHUOQm8FoF_USO4PdDCgkg7A2p53ZB5DdnKZCft79mP1sZHiPsmUkcbfb_K5SwB3dzVKROUJAjZbbp3DSxoi14H1A-Frh7ZaTBREwaPyytl7_wBoMY0oyUr5ggi6Xi-5snocl46B9U_3tvDckmmXCsaIzByVT5oQ"
        //let response = await axios.get('/searchevent/select/year', { headers: { Authorization: `Bearer ${accessToken}` } } )
        let response = await axios.get('/searchevent/select/year')
        //console.log(JSON.stringify(response.data))
        let arryr = []
        let arryrtemp = response.data
        for (var i = 0; i < arryrtemp.length; i++) {
          arryr.push(arryrtemp[i].year.toString())
        }
        commit('setYears', arryr)        
    },
    async fetchSports( { commit } ) {
        let response = await axios.get('/searchevent/select/sport')
        //console.log(JSON.stringify(response.data))
        let arrsp = []
        let arrsptemp = response.data
        for (var i = 0; i < arrsptemp.length; i++) {
          arrsp.push(arrsptemp[i].sptName)
        }
        commit('setSports', arrsp)        
    },
    async fetchCoverages( { commit } ) {
        let response = await axios.get('/searchevent/select/cover')
        //console.log(JSON.stringify(response.data))
        let arrco = []
        let arrcotemp = response.data
        for (var i = 0; i < arrcotemp.length; i++) {
          arrco.push(arrcotemp[i].coverage)
        }
        commit('setCoverages', arrco)        
    },
    async fetchHostcountries( { commit } ) {
        let response = await axios.get('/searchevent/select/host')
        //console.log(JSON.stringify(response.data))
        let arrhc = []
        let arrhctemp = response.data
        for (var i = 0; i < arrhctemp.length; i++) {
            arrhc.push(arrhctemp[i].countryName)
        }
        //console.log(JSON.stringify(arrhc))
        commit('setHostCountries', arrhc)        
    },
    async fetchSportOrgs( { commit } ) {
        let response = await axios.get('/searchevent/select/sorg')
        //console.log(JSON.stringify(response.data))
        let arrso = []
        let arrsotemp = response.data
        for (var i = 0; i < arrsotemp.length; i++) {
            arrso.push(arrsotemp[i].acronym)
        }
        commit('setSportOrgs', arrso)        
    },
    /*
    async fetchIntFederations( { commit } ) {
        let response = await axios.get('/searchevent/select/ifed')
        //console.log(JSON.stringify(response.data))
        let arrif = []
        let arriftemp = response.data
        for (var i = 0; i < arriftemp.length; i++) {
          arrif.push(arriftemp[i].acronym)
        }
        commit('setIntFederations', arrif)        
    },*/
}

