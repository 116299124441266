var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$auth.isAuthenticated)?_c('v-navigation-drawer',{attrs:{"color":_vm.color,"mini-variant":_vm.miniVariant,"app":"","dark":"","permanent":""},on:{"update:miniVariant":function($event){_vm.miniVariant=$event},"update:mini-variant":function($event){_vm.miniVariant=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',{staticClass:"py-1",attrs:{"dense":"","nav":""}},[_c('v-list-item',[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Settings")])],1)],1)],1)]},proxy:true}],null,false,2441276541),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"py-1",attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();_vm.miniVariant = !_vm.miniVariant}}},[_c('v-list-item-icon',{staticClass:"mx-0 my-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","height":"24","width":"24"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-sort-variant")])],1)]}}],null,false,3823845596)},[_c('span',[_vm._v("Open/Close Menu")])])],1)],1),_c('v-divider',{staticClass:"mb-2"}),_vm._l((_vm.itemsdsh),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":"","to":item.link}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(item.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),_c('v-divider',{staticClass:"mb-2"}),_vm._l((_vm.itemscal),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":"","to":item.link}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(item.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),_c('v-divider',{staticClass:"my-2"}),_vm._l((_vm.itemsspo),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":"","to":item.link}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(item.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }