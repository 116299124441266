<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-2" @click="fetchCalById(calid)">mdi-pencil</v-icon>
        </template>
        <v-card>
            <v-form @submit.prevent="calUpdSubmit">
                <v-card-title class="light-blue darken-3" dark>
                    <span class="headline">Update Calendar</span>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pa-8">
                    <v-row>
                        <v-col class="pr-8" cols="12" md="8"> 
                            <v-row>
                                <v-col><CalName /></v-col>
                                <v-col><CalType /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><CalDesc /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><CalOrgOwner /></v-col>
                                <v-col><CalOrgRole /></v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pl-8" cols="12" md="4">
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <p class="py-0 my-0">Color</p>
                                    <CalColor />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <p class="py-0 my-0">Owner</p>
                                    <h3>{{ $auth.user.name }}</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <p class="ma-0">Created at: {{calparams.createdAt}}<br>
                                    Last Update at: {{calparams.updatedAt}}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-card-actions class="px-0">
                                        <v-btn
                                            type="submit"
                                            color="light-blue darken-3 px-4"
                                            rounded
                                            dark
                                            @click="dialog = false"
                                        >
                                            Update
                                        </v-btn>
                                        <v-btn 
                                            type="submit"
                                            color="red darken-3 px-4"
                                            rounded
                                            dark
                                            @click="dialog = false; delCalendar(calparams.id)"
                                        >
                                            Delete
                                        </v-btn>
                                    </v-card-actions>    
                                </v-col>
                            </v-row>
                            <v-row>
                                {{calparams}}
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import CalName from './Calendarname'
import CalType from './Calendartype'
import CalDesc from './Calendardesc'
import CalOrgOwner from './Calendarorgowner'
import CalOrgRole from './Calendarorgrole'
import CalColor from './Calendarcolor'

export default {
    components: {
        CalName,
        CalType,
        CalDesc,
        CalOrgOwner,
        CalOrgRole,
        CalColor
    },
    props: [ 'calid' ],
    data: () => ({
        dialog: false
    }),
    computed: {
        ...mapState( 'calendar', [ 'calparams' ] )
    },
    methods: {
        ...mapActions( 'calendar', [ 'fetchCalByUser', 'fetchCalById', 'updCalendar', 'delCalendar' ] ),
        calUpdSubmit() {
            this.updCalendar(this.calparams)
                .then(this.fetchCalByUser(this.$auth.user.sub.slice(6)))
        }
    },
}
</script>

<style>
    .headline {
        color: white;
    }
    .collor-picker-new {
        max-width: none !important;
    }
</style>