<template>
    <v-app>
        <MainMenu />
        <MainAppBar />
        <v-main>
            <v-container fluid>
                <v-row class="mx-0 my-1">
                    <v-col class="pa-0" cols="12" md="12">
                        <EventToolBar />
                        <EventDataTable />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import MainMenu from '@/components/MainMenu'
import MainAppBar from '@/components/MainAppBar'
import EventToolBar from '@/components/event/Eventtoolbar'
import EventDataTable from '@/components/event/Eventdatatable'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Eventmanagement',
    components: {
        MainMenu,
        MainAppBar,
        EventToolBar,
        EventDataTable
    },
}
</script>

<style>

</style>