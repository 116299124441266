<template>
    <v-app>
        <MainMenu />
        <MainAppBar />
        <v-main>
            <v-container fluid>
                <v-row class="mx-0 my-1">
                    <v-col class="pa-0" cols="12" md="12">
                        <SportToolBar />
                        <SportDataTable />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import MainMenu from '@/components/MainMenu'
import MainAppBar from '@/components/MainAppBar'
import SportToolBar from '@/components/sport/Sporttoolbar'
import SportDataTable from '@/components/sport/Sportdatatable'

export default {
    name: 'OrgManagement',
    components: {
        MainMenu,
        MainAppBar,
        SportToolBar,
        SportDataTable
    },
}
</script>

<style>

</style>