import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Searchevent from '../views/Searchevent.vue'
import Profile from "../views/Userprofile.vue"
import Viewcalendar from "../views/Viewcalendar.vue"
import Calendar from "../views/Calendar.vue"
import Dashboard from "../views/Dashboard.vue"
import Event from "../views/Event.vue"
import Organisation from "../views/Organisation.vue"
import Sport from "../views/Sport.vue"
import User from "../views/User.vue"
import { authenticationGuard } from "@/auth/authenticationGuard"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/searchevent',
    name: 'Searchevent',
    component: Searchevent
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/viewcalendar",
    name: "viecalendar",
    component: Viewcalendar,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/calendar",
    name: "calendar",
    component: Calendar,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/event",
    name: "event",
    component: Event,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/organisation",
    name: "organisation",
    component: Organisation,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/sport",
    name: "sport",
    component: Sport,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/user",
    name: "user",
    component: User,
    beforeEnter: authenticationGuard,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
