<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="light-blue darken-3 py-2" @click="clearApptData" rounded dark>
                <v-icon left>mdi-plus</v-icon>Create
            </v-btn>
        </template>
        <v-card>
            <v-form @submit.prevent="apptAddSubmit">
                <v-card-title class="light-blue darken-3" dark>
                    <span class="headline">Create a New Appointment</span>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-container>
                <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    label="Title" 
                                    v-model="apptparams.appttitle" 
                                    hide-details>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-menu v-model="fromDateMenu"
                                    :close-on-content-click="false"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                    >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            placeholder="Initial Date"
                                            readonly
                                            :value="apptparams.apptbg"
                                            v-on="on"
                                            outlined dense hide-details
                                            append-icon="mdi-calendar"
                                            clearable
                                            clear-icon="mdi-close"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="apptparams.apptbg"
                                        no-title
                                        @input="fromDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="timeini"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="timeini"
                                            label="Initial Time"
                                            append-icon="mdi-clock-time-four-outline"
                                            readonly outlined dense hide-details clearable
                                            clear-icon="mdi-close"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="menu2"
                                        v-model="timeini"
                                        full-width
                                        no-title
                                        @click:minute="$refs.menu2.save(timeini)"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu v-model="toDateMenu"
                                    :close-on-content-click="false"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            placeholder="End Date"
                                            readonly
                                            :value="apptparams.apptend"
                                            v-on="on"
                                            outlined dense hide-details
                                            append-icon="mdi-calendar"
                                            clearable
                                            clear-icon="mdi-close"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="apptparams.apptend"
                                        no-title
                                        @input="toDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu
                                    ref="menu4"
                                    v-model="menu4"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="timeend"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="timeend"
                                            label="End Time"
                                            append-icon="mdi-clock-time-four-outline"
                                            readonly outlined dense hide-details clearable
                                            clear-icon="mdi-close"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="menu4"
                                        v-model="timeend"
                                        full-width
                                        no-title
                                        @click:minute="$refs.menu4.save(timeend)"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    label="Local" 
                                    v-model="apptparams.apptlocal" 
                                    hide-details>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea 
                                    label="Description" 
                                    v-model="apptparams.apptdesc" 
                                    rows="3">
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <p class="py-0 my-0">Color</p>
                                <v-color-picker
                                    v-model="apptparams.apptcolor"
                                    dot-size="20"
                                    hide-canvas
                                    hide-inputs
                                    hide-mode-switch
                                    hide-sliders
                                    show-swatches
                                    swatches-max-height="150"
                                ></v-color-picker>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select
                                    v-model="getCalendars.CALENDAR_ID"
                                    :items="getCalendars"
                                    item-value="CALENDAR_ID"
                                    item-text="CALENDAR_NAME"
                                    placeholder="Calendar"
                                    append-icon="mdi-chevron-down"
                                    outlined dense hide-details
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <p class="py-0 my-0">Owner</p>
                                <h3>{{ $auth.user.name }}</h3>
                            </v-col>
                        </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" color="blue darken-1" text @click="dialog = false">
                        Create
                    </v-btn>
                </v-card-actions>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    data: () => ({
        dialog: false,
        timeini: null,
        timeend: null,
        menu2: false,
        menu4: false,
        toDateMenu: false,
        fromDateMenu: false,
        calendars: []
    }),
    computed: {
        ...mapState([ 'apptparams', 'calsel' ]),
        ...mapGetters([ 'getCalendars' ]),
    },
    methods: {
        ...mapActions([ 'addAppointment', 'fetchAppointments' ]),
        apptAddSubmit() {
            let params = {
                APT_ID: this.apptparams.apptid,
                DT_INSERT: new Date().toISOString().substr(0, 10),
                DT_BEGIN: this.apptparams.apptbg.concat('T').concat(this.timeini).concat(':00Z'),
                DT_END: this.apptparams.apptend.concat('T').concat(this.timeend).concat(':00Z'),
                APT_TITLE: this.apptparams.appttitle,
                APT_DESC: this.apptparams.apptdesc,
                APT_LOCAL: this.apptparams.apptlocal,
                APT_COLOR: this.apptparams.apptcolor,
                APT_RECURRENCE: this.apptparams.apptrecur,
                CALENDAR_ID: this.getCalendars.CALENDAR_ID,
                UID_OWNER: this.$auth.user.sub.slice(6),
                UID_SHARED: this.apptparams.apptshared
            }
            this.addAppointment(params).then(() => {
                const calssel = {calendarid: this.calsel}
                this.fetchAppointments(calssel)
                //console.log(JSON.stringify(calssel))
            })
        },
        /*
        updCalAppts(arrcalsel) {
            const calssel = {calendarid: arrcalsel}
            this.fetchAppointments(calssel)
            console.log(JSON.stringify(calssel))
        },*/
        clearApptData() {
            this.apptparams.appttitle = ''
            this.apptparams.apptbg = ''
            this.timeini = ''
            this.apptparams.apptend = ''
            this.timeend = ''
            this.apptparams.apptdesc = ''
            this.apptparams.apptlocal = ''
            this.apptparams.apptcolor = ''
            this.getCalendars.CALENDAR_ID = ''
        },
    },
}
</script>

<style>
    .headline {
        color: white;
    }
</style>