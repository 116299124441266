<template>
   <v-text-field
        label="Sport Link Agenda"
        v-model="isSportLkAgenda"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'sport', [ 'sportparams' ] ),
        isSportLkAgenda: {
            get() { return this.sportparams.sptLkAgenda },
            set(item) { this.upParamsSptlkagenda(item) }
        }
    },
    methods: {
        ...mapMutations( 'sport', [ 'upParamsSptlkagenda' ] )
    }
}
</script>

<style>

</style>