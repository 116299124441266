<template>
  <v-app>
    <MainMenu />
    <MainAppBar />
    <v-main>
        <v-container fluid>
            <v-row>
                <v-col>
                    <v-card elevation="2">
                        <v-system-bar
                            color="blue darken-2"
                            dark
                            >
                            <v-spacer></v-spacer>

                            <v-icon>mdi-window-minimize</v-icon>

                            <v-icon>mdi-window-maximize</v-icon>

                            <v-icon>mdi-close</v-icon>
                            </v-system-bar>

                            <v-app-bar
                            dark
                            color="blue"
                            >
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>

                            <v-toolbar-title>My Calendar Day</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-app-bar>
                        <v-calendar
                        color="primary"
                        type="day"
                        >
                            <template v-slot:day-header="{ present }">
                                <template
                                v-if="present"
                                class="text-center"
                                >
                                Today
                                </template>
                            </template>

                            <template v-slot:interval="{ hour }">
                                <div
                                class="text-center"
                                >
                                {{ hour }} o'clock
                                </div>
                            </template>
                        </v-calendar>
                    </v-card>
                </v-col>
                <v-col>
                    <v-row>
                        <v-col>
                    <v-card>
                        <v-system-bar
                            color="blue darken-2"
                            dark
                            >
                            <v-spacer></v-spacer>

                            <v-icon>mdi-window-minimize</v-icon>

                            <v-icon>mdi-window-maximize</v-icon>

                            <v-icon>mdi-close</v-icon>
                            </v-system-bar>

                            <v-app-bar
                            dark
                            color="blue"
                            >
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>

                            <v-toolbar-title>Upcomming Events</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-app-bar>
                        <v-data-table
                            :headers="headers"
                            :items="desserts"
                            class="elevation-1"
                        >
                            <template v-slot:[`item.calories`]="{ item }">
                            <v-chip
                                :color="getColor(item.calories)"
                                dark
                            >
                                {{ item.calories }}
                            </v-chip>
                            </template>
                        </v-data-table>
                    </v-card>
                    </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                    <v-card>
                        <v-system-bar
                            color="blue darken-2"
                            dark
                            >
                            <v-spacer></v-spacer>

                            <v-icon>mdi-window-minimize</v-icon>

                            <v-icon>mdi-window-maximize</v-icon>

                            <v-icon>mdi-close</v-icon>
                            </v-system-bar>

                            <v-app-bar
                            dark
                            color="blue"
                            >
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>

                            <v-toolbar-title>My Teams</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-app-bar>
                        <v-data-table
                            :headers="headers"
                            :items="desserts"
                            :items-per-page="10"
                            class="elevation-1"
                        ></v-data-table>
                    </v-card>
                    </v-col>
                    </v-row>
                </v-col>
                <v-col>
                    <v-card>
                        <v-system-bar
                            color="blue darken-2"
                            dark
                            >
                            <v-spacer></v-spacer>

                            <v-icon>mdi-window-minimize</v-icon>

                            <v-icon>mdi-window-maximize</v-icon>

                            <v-icon>mdi-close</v-icon>
                            </v-system-bar>

                            <v-app-bar
                            dark
                            color="blue"
                            >
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>

                            <v-toolbar-title>My Teams</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-app-bar>
                        <v-data-table
                            :headers="headers"
                            :items="desserts"
                            :items-per-page="10"
                            class="elevation-1"
                        ></v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex' 
import MainMenu from '@/components/MainMenu'
import MainAppBar from '@/components/MainAppBar'

export default {
    name: 'Userprofile',
    components: {
        MainMenu,
        MainAppBar
    },
    data: () => ({
        labels: ['SU', 'MO', 'TU', 'WED', 'TH', 'FR', 'SA'],
        time: 0,
        forecast: [
          { day: 'Tuesday', icon: 'mdi-white-balance-sunny', temp: '24\xB0/12\xB0' },
          { day: 'Wednesday', icon: 'mdi-white-balance-sunny', temp: '22\xB0/14\xB0' },
          { day: 'Thursday', icon: 'mdi-cloud', temp: '25\xB0/15\xB0' },
        ],
        headers: [
          {
            text: 'Dessert (100g serving)',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Calories', value: 'calories' },
          { text: 'Fat (g)', value: 'fat' },
          { text: 'Carbs (g)', value: 'carbs' },
          { text: 'Protein (g)', value: 'protein' },
          { text: 'Iron (%)', value: 'iron' },
        ],
        desserts: [
          {
            name: 'Frozen Yogurt',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: '1%',
          },
          {
            name: 'Ice cream sandwich',
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: '1%',
          },
          {
            name: 'Eclair',
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            iron: '7%',
          },
          {
            name: 'Cupcake',
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
            iron: '8%',
          },
          {
            name: 'Gingerbread',
            calories: 356,
            fat: 16.0,
            carbs: 49,
            protein: 3.9,
            iron: '16%',
          },
          {
            name: 'Jelly bean',
            calories: 375,
            fat: 0.0,
            carbs: 94,
            protein: 0.0,
            iron: '0%',
          },
          {
            name: 'Lollipop',
            calories: 392,
            fat: 0.2,
            carbs: 98,
            protein: 0,
            iron: '2%',
          },
          {
            name: 'Honeycomb',
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
            iron: '45%',
          },
          {
            name: 'Donut',
            calories: 452,
            fat: 25.0,
            carbs: 51,
            protein: 4.9,
            iron: '22%',
          },
          {
            name: 'KitKat',
            calories: 518,
            fat: 26.0,
            carbs: 65,
            protein: 7,
            iron: '6%',
          },
        ],
    }),
    methods: {
      getColor (calories) {
        if (calories > 400) return 'red'
        else if (calories > 200) return 'orange'
        else return 'green'
      },
    },
}
</script>

<style>

</style>