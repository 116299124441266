<template>
    <v-toolbar flat>
        <v-toolbar-title>Event Management</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <EventCreate />
    </v-toolbar>
</template>

<script>
import EventCreate from '@/components/event/Eventcreate'

export default {
    name: 'Eventtoolbar',
    components: {
        EventCreate
    }
}
</script>

<style>

</style>