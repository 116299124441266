<template>
  <v-data-table
        :headers="headers"
        :items="getUsers"
        :items-per-page="50"
        item-key="name"
    >
        <template v-slot:[`item.picture`]="{ item }">
            <v-avatar class="my-2">
                <v-img :src="item.picture"></v-img>
            </v-avatar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <SportUpdate :sptid="item.id"/>
            <v-icon @click="delUser(item.user_id)">mdi-delete</v-icon>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import SportUpdate from '@/components/sport/Sportupdate'

export default {
    name: 'Userdatatable',
    components: {
        SportUpdate
    },
    data () {
        return {
            drawerLeft: false,
            headers: [
                { text: '', align: 'left', sortable: false, value: 'picture' },
                { text: 'User', align: 'left', sortable: false, value: 'name' },
                { text: 'Nickname', align: 'left', sortable: false, value: 'nickname' },
                { text: 'Email', align: 'left', sortable: false, value: 'email' },
                { text: 'User ID', align: 'left', sortable: false, value: 'user_id' },
                { text: 'Actions', align: 'center', value: 'actions', sortable: false },
            ],
            expanded: [],
            singleExpand: true,
        }
    },
    mounted() {
        this.fetchUsers()
    },
    computed: {
        ...mapGetters( 'user', [ 'getUsers' ] )
    },
    methods: {
        ...mapActions( 'user', [ 'fetchUsers', 'delUser' ])
    }
}
</script>

<style>

</style>