<template>
    <v-main>
        <v-container pa-0 fill-height fluid>
            <v-row class="hm-row1" no-gutters>
                <Homeheadertoolbar />
                <v-row class="row-logo">
                    <v-card class="sptf-logo-card d-flex">
                        <v-img class="sptf-logo-img" src="@/assets/sptf-logo.png" contain></v-img>
                    </v-card>
                </v-row>
            </v-row>
            <v-row class="hm-row2" no-gutters>
                <v-form class="hm-main-form" @submit.prevent="searchSubmit">
                    <v-row class="my-4" no-gutters>
                        <v-col class="pr-2" cols="12" md="8">
                            <Hometxtsearch />
                        </v-col>
                        <v-col class="pl-2" cols="12" md="4">
                            <Homesubmitbtn />
                        </v-col>
                    </v-row>
                    <v-row class="my-4" no-gutters>
                        <v-col class="pr-2" cols="12" md="4">
                            <Homeyear />
                        </v-col>
                        <v-col class="px-2" cols="12" md="4">
                            <Homeinidate />
                        </v-col>
                        <v-col class="pl-2" cols="12" md="4">
                            <Homefindate />
                        </v-col>
                    </v-row>
                    <v-row class="my-4" no-gutters>
                        <v-col class="pr-2" cols="12" md="4">
                            <Homesport />
                        </v-col>
                        <v-col class="px-2" cols="12" md="4">
                            <Homehostcountry />
                        </v-col>
                        <v-col class="pl-2" cols="12" md="4">
                            <Homelivestream /> 
                        </v-col>
                    </v-row>                
                    <v-row class="my-4 no-gutters">
                        <v-col class="d-flex justify-end" cols="12" md="12">
                            <Homeclearform />
                            <Homeadvancedsearch />
                        </v-col>
                        <!--
                        <v-col cols="12" md="4"></v-col>
                        <v-col class="d-flex justify-center" cols="12" md="4">
                            <Homeadvancedsearch />
                        </v-col> -->                      
                    </v-row>
                </v-form>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Homeheadertoolbar from '@/components/home/Homeheadertoolbar'
import Hometxtsearch from '@/components/home/Hometxtsearch'
import Homesubmitbtn from '@/components/home/Homesubmitbtn'
import Homeyear from '@/components/home/Homeyear'
import Homeinidate from '@/components/home/Homeinidate'
import Homefindate from '@/components/home/Homefindate'
import Homesport from '@/components/home/Homesport'
import Homehostcountry from '@/components/home/Homehostcountry'
import Homelivestream from '@/components/home/Homelivestream'
import Homeclearform from '@/components/home/Homeclearform'
import Homeadvancedsearch from '@/components/advancedsearch/Advancedsearch'

export default {
  name: 'Home',
  components: {
    Hometxtsearch,
    Homesubmitbtn,
    Homeyear,
    Homeinidate,
    Homefindate,
    Homesport,
    Homelivestream,
    Homehostcountry,
    Homeclearform,
    Homeadvancedsearch,
    Homeheadertoolbar
  },
  computed: {
    ...mapState( 'searchevent', [ 'searchparams' ] ),
  },
  methods: {
    //...mapActions( 'searchevent', [ 'fetchEvents' ] ),
    searchSubmit() {
        //await this.$router.push({ path: './Searchevent', query: this.searchparams })
        let params = {
            //txtsearch: this.searchparams.txtsearch,
            txtsearch: (this.searchparams.txtsearch != '') ? this.searchparams.txtsearch : [],
            year: (this.searchparams.year != '') ? this.searchparams.year : [],
            //inidate: this.searchparams.inidate,
            inidate: (this.searchparams.inidate != '') ? this.searchparams.inidate : [],
            //findate: this.searchparams.findate,
            findate: (this.searchparams.findate != '') ? this.searchparams.findate : [],
            sport: (this.searchparams.sport != '') ? this.searchparams.sport : [],
            coverage: (this.searchparams.coverage != '') ? this.searchparams.coverage : [],
            hostcountry: (this.searchparams.hostcountry != '') ? this.searchparams.hostcountry : [],
            sportorg: (this.searchparams.sportorg != '') ? this.searchparams.sportorg : [],
            //intfed: (this.searchparams.intfed != '') ? this.searchparams.intfed : [],
            eventtype: (this.searchparams.eventtype != '') ? this.searchparams.eventtype : [],
            season: (this.searchparams.season != '') ? this.searchparams.season : [],
            olyply: (this.searchparams.olyply != '') ? this.searchparams.olyply : [],
            genre: (this.searchparams.genre != '') ? this.searchparams.genre : [],
            livestream: (this.searchparams.livestream != '') ? this.searchparams.livestream : [],
            ticket: (this.searchparams.ticket != '') ? this.searchparams.ticket : []
        }
        
        this.$router.push({ path: './Searchevent'}) //, query: this.searchparams })
    },
  }
}
</script>

<style scoped>

  .hm-row1,
  .hm-row2 {
    height: 50%;
    justify-content: center;
  }

  .hm-row1 {
    background-position: center center;    
    background-repeat: no-repeat;
    background-size: cover;
    animation: slideBg 10s ease-in-out infinite 0s;
    display: flex;
    flex-direction: column;        
    align-content: center;
    -webkit-align-content: center;
  }

  @keyframes slideBg {
    0% { background-image: url(../assets/hd-bgimage1.png); }
    33% { background-image: url(../assets/hd-bgimage2.png); }
    66% { background-image: url(../assets/hd-bgimage3.png); }
    100% { background-image: url(../assets/hd-bgimage1.png); }
  }

  .row-logo {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 0;
    width: 100%;
  }

  .sptf-logo-card {
    max-width: 40%;
    height: 80px;
    justify-content: center;
    padding: 0 7%;
    border-radius: 50px !important;
  }
  
  .sptf-logo-img {
    border-radius: 0 !important;
  }

  .hm-main-form {
    max-width: 40%;
  }

  .bd-rad {
    border-radius: 50px;
    font-size: 14px;
  }

  .fm-btn {
    height: 100% !important;
  }

</style>
