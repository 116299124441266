<template>
    <v-list class="pa-0" dense>
        <v-list-item-group multiple>
            <v-list-item-content class="pt-0">
                <v-list-item-title>Season Sport Type:</v-list-item-title>
            </v-list-item-content>
            <v-list-item class="pa-0" @click="toggle">
                <v-list-item-action>
                    <v-icon>{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>All Seasons</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item class="pa-0" v-for="item in seasons" :key="item">
                <v-list-item-action>
                    <v-checkbox v-model="isSeason" :value="item"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'seasons', 'searchparams' ] ),
        isSeason: {
            get() { return this.searchparams.season },
            set(item) { this.upParamsSs(item) }
        },
        selAllSeason () {
            return this.searchparams.season.length === this.seasons.length
        },
        selSomeSeason () {
            return this.searchparams.season.length > 0 && !this.selAllSeason
        },
        icon () {
            if (this.selAllSeason) return 'mdi-close-box'
            if (this.selSomeSeason) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsSs' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllSeason) {
                this.searchparams.season = []
            } else {
                this.searchparams.season = this.seasons.slice()
            }
            })
        },
    }
}
</script>

<style>

</style>