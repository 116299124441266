<template>
    <v-app-bar
        class="sptf-bar elevation-0"
        app
        dense
    >
        <router-link to="/">
            <v-img src="../assets/sptf-logo.png" height="20" contain position="left"></v-img>
        </router-link>
        <v-spacer></v-spacer>
        
        <span class="menu-hd-toolbar">
            <v-btn class="tool-btn" text small color="black" @click="sptfLogin" v-if="!$auth.isAuthenticated">Log In</v-btn>
            <v-btn class="tool-btn" text small color="black" @click="sptfSignup" v-if="!$auth.isAuthenticated">Sign Up</v-btn>
            <MainUserMenu />
        </span>
        
    </v-app-bar>
</template>

<script>
import MainUserMenu from '@/components/MainUserMenu'

export default {
    name: 'MainAppBar',
    components: {
        MainUserMenu,
    },
    methods: {
        sptfLogin(){
            this.$auth.loginWithRedirect();
            console.log('we are in auth0Login');
        },
        sptfSignup() {
            this.$auth.loginWithRedirect({
                screen_hint: "signup",
            });
        },
        sptfLogout() {
            this.$auth.logout();
            this.$router.push({ path: "/" });
            console.log('logging out')
        }
    },
}
</script>

<style>
    .menu-hd-toolbar {
        color: black;
    }
    .sptf-bar {
        border-bottom: solid 1px;
        border-bottom-color: rgba(0, 0, 0, 0.12) !important;
        background-color: white !important;
    }
</style>