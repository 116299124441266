<template>
    <v-select
        class="bd-rad"
        v-model="isHostcountries"
        :items="hostcountries"
        placeholder="Host Countries"
        append-icon="mdi-chevron-down"
        outlined
        dense
        hide-details
        :menu-props="{ maxHeight: '400' }"
        multiple
    >
        <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
                <v-list-item-action>
                    <v-icon :color="searchparams.hostcountry.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Select All
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
                {{ item }}
            </span>
            <span
                v-if="index === 1"
                class="grey--text text-caption pl-1"
            >
                (+{{ searchparams.hostcountry.length - 1 }} others)
            </span>
        </template>
    </v-select>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'hostcountries', 'searchparams' ] ),
        isHostcountries: {
            get() { return this.searchparams.hostcountry },
            set(item) { this.upParamsHc(item) }
        },
        selAllHostCountries () {
            return this.searchparams.hostcountry.length === this.hostcountries.length
        },
        selSomeHostCountries () {
            return this.searchparams.hostcountry.length > 0 && !this.selAllHostCountries
        },
        icon () {
            if (this.selAllHostCountries) return 'mdi-close-box'
            if (this.selSomeHostCountries) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    mounted() {
        this.fetchHostcountries()
    },
    methods: {
        ...mapActions( 'searchevent', [ 'fetchHostcountries' ]),
        ...mapMutations( 'searchevent', [ 'upParamsHc' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllHostCountries) {
                this.searchparams.hostcountry = []
            } else {
                this.searchparams.hostcountry = this.hostcountries.slice()
            }
            })
        },
    },
}
</script>

<style>

    .bd-rad {
        border-radius: 50px;
        font-size: 14px;
    }

</style>