<template>
    <v-text-field class="fm-search bd-rad" name="txtsearch"
        v-model="isTxtSearch"
        placeholder="Insert words for your search"
        outlined
        clearable
        dense
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'searchparams' ] ),
        isTxtSearch: {
            get() { return this.searchparams.txtsearch },
            set(item) { this.upParamsTx(item) }
        }
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsTx' ] )
    }
}
</script>

<style>

</style>