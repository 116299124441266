<template>
    <v-list class="form-list-menu-left py-0" dense>
        <v-list-group no-action append-icon="mdi-chevron-down">
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title>Sports</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item  class="px-4" @click="toggle">
                <v-list-item-action class="my-0">
                    <v-icon>{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>All Sports</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="item in sports" :key="item" class="px-4">
                <v-list-item-action class="my-0">
                    <v-checkbox v-model="isSports" :value="item"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
    </v-list>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'sports', 'searchparams' ] ),
        isSports: {
            get() { return this.searchparams.sport },
            set(item) { this.upParamsSp(item) }
        },
        selAllSports() {
            return this.searchparams.sport.length === this.sports.length
        },
        selSomeSports () {
            return this.searchparams.sport.length > 0 && !this.selAllSports
        },
        icon () {
            if (this.selAllSports) return 'mdi-close-box'
            if (this.selSomeSports) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsSp' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllSports) {
                this.searchparams.sport = []
            } else {
                this.searchparams.sport = this.sports.slice()
            }
            })
        },
    },
}
</script>

<style>

    .v-list-item__action:first-child {
        margin-right: 16px !important;
    }

</style>