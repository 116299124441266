<template>
    <v-autocomplete
        v-model="isCalendar"
        :items="cals"
        item-value="calId"
        item-text="calName"
        dense
        filled
        hide-details
        label="Tournament/Initial Calendar"
        @change="onChange"
    ></v-autocomplete>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    mounted() {
        this.fetchCals(this.$auth.user.sub.slice(6))
    },
    computed: {
        ...mapState( 'event', [ 'eventparams', 'cals' ] ),
        isCalendar: {
            get() { return this.eventparams.calId },
            set(item) { this.upParamsCalid(item) }
        }
    },
    methods: {
        ...mapActions( 'event', [ 'fetchCals'] ),
        ...mapMutations( 'event', [ 'upParamsCalid', 'upParamsCalname' ] ),
        onChange(item) {
            let calitem = this.cals.find(calitem => calitem.calId === item);
            this.upParamsCalname(calitem.calName)
        }
    }
}
</script>

<style>

</style>