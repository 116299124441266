<template>
   <v-text-field
        label="User Report"
        v-model="isOrgReport"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'user', [ 'userparams' ] ),
        isOrgReport: {
            get() { return this.userparams.user_metadata.orgReport },
            set(item) { this.upParamsOrgReport(item) }
        }
    },
    methods: {
        ...mapMutations( 'user', [ 'upParamsOrgReport' ] )
    }
}
</script>

<style>

</style>