<template>
    <v-textarea 
        label="Description" 
        v-model="isEventDesc"
        filled
        hide-details
        rows="3">
    </v-textarea>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'event', [ 'eventparams' ] ),
        isEventDesc: {
            get() { return this.eventparams.aptDesc },
            set(item) { this.upParamsAptDesc(item) }
        }
    },
    methods: {
        ...mapMutations( 'event', [ 'upParamsAptDesc' ] )
    }
}
</script>

<style>

</style>