<template>
    <v-menu v-model="fromDateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                placeholder="Initial Date"
                readonly
                :value="isInidate"
                v-on="on"
                outlined 
                hide-details
                append-icon="mdi-calendar"
                clearable
                clear-icon="mdi-close"
                @click:clear="upParamsDtBegin('')"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="isInidate"
            no-title
            @input="fromDateMenu = false"
            @change="onChange"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data: () => ({
        fromDateMenu: false
    }),
    computed: {
        ...mapState( 'event', [ 'eventparams' ] ),
        isInidate: {
            get() { return this.eventparams.dtBegin },
            set(inidate) { this.upParamsDtBegin(inidate) }
        }
    },
    methods: {
        ...mapMutations( 'event', [ 'upParamsDtBegin', 'upParamsDtBeginYear' ] ),
        onChange(inidate) {
            let dbyear = inidate.slice(0,4)
            this.upParamsDtBeginYear(dbyear)
        }
    },
}
</script>

<style>

</style>