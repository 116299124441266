<template>
   <v-text-field
        label="User Org Position"
        v-model="isOrgPosition"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'user', [ 'userparams' ] ),
        isOrgPosition: {
            get() { return this.userparams.user_metadata.orgPosition },
            set(item) { this.upParamsOrgPosition(item) }
        }
    },
    methods: {
        ...mapMutations( 'user', [ 'upParamsOrgPosition' ] )
    }
}
</script>

<style>

</style>