<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="light-blue darken-3 py-2" @click="clearSportData" rounded dark>
                <v-icon left>mdi-plus</v-icon>Create
            </v-btn>
        </template>
        <v-card>
            <v-form @submit.prevent="sptAddSubmit">
                <v-card-title class="light-blue darken-3" dark>
                    <span class="headline">Create a New Organisation</span>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pa-8">
                    <v-row>
                        <v-col class="pr-8" cols="12" md="8"> 
                            <v-row>
                                <v-col><SportName /></v-col>
                                <v-col><SportGroup /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><SportOrgUp /></v-col>
                                <v-col><SportLkAgenda /></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="3"><SportIndivTeam /></v-col>
                                <v-col cols="12" md="3"><SportOlyPly /></v-col>
                                <v-col cols="12" md="3"><SportSeason /></v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pl-8" cols="12" md="4">
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <p class="py-0 my-0">Owner</p>
                                    <h3>{{ $auth.user.name }}</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-card-actions class="px-0">
                                        <v-btn type="submit" color="light-blue darken-3 px-4" rounded dark @click="dialog = false">
                                            Create
                                        </v-btn>
                                    </v-card-actions>    
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import SportName from './Sportname'
import SportGroup from './Sportgroup'
import SportOrgUp from './Sportorgup'
import SportLkAgenda from './Sportlkagenda'
import SportIndivTeam from './Sportindivteam'
import SportOlyPly from './Sportolyply'
import SportSeason from './Sportseason'

export default {
    components: {
        SportName,
        SportGroup,
        SportOrgUp,
        SportLkAgenda,
        SportIndivTeam,
        SportOlyPly,
        SportSeason
    },
    data: () => ({
        dialog: false
    }),
    mounted() {
        this.upParamsInsertedby(this.$auth.user.sub.slice(6)),
        this.upParamsUpdatedby(this.$auth.user.sub.slice(6))
    },
    computed: {
        ...mapState( 'sport', [ 'sportparams' ] )
    },
    methods: {
        ...mapActions( 'sport', [ 'addSport' ] ),
        ...mapMutations( 'sport', [ 'upParamsInsertedby', 'upParamsUpdatedby' ] ),
        sptAddSubmit() {
            this.addSport(this.sportparams)
        },
        clearSportData() {
            this.sportparams.sptName = '',
            this.sportparams.sptGroup = '',
            this.sportparams.orgUp = '',
            this.sportparams.sptLkAgenda = '',
            this.sportparams.indivTeam = '',
            this.sportparams.olyply = '',
            this.sportparams.season = ''
        }
    },
}
</script>

<style>
    .headline {
        color: white;
    }
    .collor-picker-new {
        max-width: none !important;
    }
</style>