<template>
    <v-toolbar flat>
        <v-toolbar-title>Calendar Management</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <CalendarCreate />
    </v-toolbar>
</template>

<script>
import CalendarCreate from '@/components/calendar/Calendarcreate'

export default {
    name: 'Calendartoolbar',
    components: {
        CalendarCreate
    }
}
</script>

<style>

</style>