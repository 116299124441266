<template>
    <v-list class="pa-0" dense>
        <v-list-item-group multiple>
            <v-list-item-content class="pt-0">
                <v-list-item-title>Event Tickets:</v-list-item-title>
            </v-list-item-content>
            <v-list-item class="pa-0" @click="toggle">
                <v-list-item-action>
                    <v-icon>{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>All Tickets</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item class="pa-0" v-for="item in tickets" :key="item">
                <v-list-item-action>
                    <v-checkbox v-model="isTickets" :value="item"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'tickets', 'searchparams' ] ),
        isTickets: {
            get() { return this.searchparams.ticket },
            set(item) { this.upParamsTk(item) }
        },
        selAllTickets () {
            return this.searchparams.ticket.length === this.tickets.length
        },
        selSomeTickets () {
            return this.searchparams.ticket.length > 0 && !this.selAllTickets
        },
        icon () {
            if (this.selAllTickets) return 'mdi-close-box'
            if (this.selSomeTickets) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsTk' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllTickets) {
                this.searchparams.ticket = []
            } else {
                this.searchparams.ticket = this.tickets.slice()
            }
            })
        },
    }
}
</script>

<style>

</style>