<template>
    <v-list class="form-list-menu-left py-0" dense>
        <v-list-group no-action append-icon="mdi-chevron-down">
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title>Host Countries</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item  class="px-4" @click="toggle">
                <v-list-item-action class="my-0">
                    <v-icon>{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>All Host Countries</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="item in hostcountries" :key="item" class="px-4">
                <v-list-item-action class="my-0">
                    <v-checkbox v-model="isHostcountries" :value="item"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
    </v-list>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'hostcountries', 'searchparams' ] ),
        isHostcountries: {
            get() { return this.searchparams.hostcountry },
            set(item) { this.upParamsHc(item) }
        },
        selAllHostcountries () {
            return this.searchparams.hostcountry.length === this.hostcountries.length
        },
        selSomeHostcountries () {
            return this.searchparams.hostcountry.length > 0 && !this.selAllHostcountries
        },
        icon () {
            if (this.selAllHostcountries) return 'mdi-close-box'
            if (this.selSomeHostcountries) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsHc' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllHostcountries) {
                this.searchparams.hostcountry = []
            } else {
                this.searchparams.hostcountry = this.hostcountries.slice()
            }
            })
        },
    },
}
</script>

<style>

</style>