var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"sptf-search-table elevation-1",attrs:{"headers":_vm.headers,"items":_vm.searchEvents,"items-per-page":50,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"expand-icon":"mdi-chevron-down","show-expand":"","item-key":"title"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.year",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"year-chip",attrs:{"color":_vm.getColor(item.year),"dark":""}},[_vm._v(_vm._s(item.year))])]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"totperiod"},[_c('div',{staticClass:"iniperiod"},[_c('div',[_vm._v(_vm._s(item.dtBegin.slice(8,10))+" - "+_vm._s(_vm.getMonth(item.dtBegin.slice(5,7))))])]),_c('div',{staticClass:"endperiod"},[_c('div',[_vm._v(_vm._s(item.dtEnd.slice(8,10))+" - "+_vm._s(_vm.getMonth(item.dtEnd.slice(5,7))))])])])]}},{key:"item.calName",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.calName))]),_c('br'),_vm._v(_vm._s(item.title.substr(0, 40) + '...')+" ")]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"city-link",attrs:{"href":'https://www.google.de/maps/place/'+item.city,"target":"_blank"}},[_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(item.city)+" ")],1)]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"country-flag",attrs:{"src":require('@/assets/flags/'+item.countryFlag.toLowerCase()+'.png'),"aspect-ratio":"0.7","contain":"","title":item.countryName}})]}},{key:"item.ticket",fn:function(ref){
var item = ref.item;
return [(item.ticket != '')?_c('v-chip',{staticClass:"ticket-chip",attrs:{"color":_vm.getTicketColor(item.ticket),"small":"","dark":""}},[_vm._v(_vm._s(item.ticket.toUpperCase()))]):_c('v-chip',{staticClass:"ticket-chip",attrs:{"color":_vm.getTicketColor(item.ticket),"small":"","dark":""}},[_vm._v("NOTDEF")])]}},{key:"item.livestream",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"lsicon-group"},[(item.lkStreamOs != '')?_c('v-icon',{staticClass:"px-1",attrs:{"href":item.lkStreamOs,"target":"_blank","color":"yellow darken-2"}},[_vm._v("fab fa-font-awesome")]):_vm._e(),(item.lkStreamPp != '')?_c('v-icon',{staticClass:"px-1",attrs:{"href":item.lkStreamPp,"target":"_blank","color":"black"}},[_vm._v("fab fa-pied-piper-pp")]):_vm._e(),(item.lkStreamFb != '')?_c('v-icon',{staticClass:"px-1",attrs:{"href":item.lkStreamFb,"target":"_blank","color":"blue"}},[_vm._v("fab fa-facebook-square")]):_vm._e(),(item.lkStreamYt != '')?_c('v-icon',{staticClass:"px-1",attrs:{"href":item.lkStreamYt,"target":"_blank","color":"red"}},[_vm._v("fab fa-youtube-square")]):_vm._e(),(item.lkStreamTw != '')?_c('v-icon',{staticClass:"px-1",attrs:{"href":item.lkStreamTw,"target":"_blank","color":"blue"}},[_vm._v("fab fa-twitter-square")]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"exp-tb pa-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',[_c('thead',{staticClass:"exp-theader"},[_c('tr',[_c('th',[_vm._v("Event Type")]),_c('th',[_vm._v("Coverage")]),_c('th',[_vm._v("Genre")]),_c('th',[_vm._v("Sport Season")]),_c('th',[_vm._v("Oly/Ply")]),_c('th',[_vm._v("Sport Organisation")]),_c('th',[_vm._v("Event OC")])])]),_c('tbody',{staticClass:"exp-tbody"},[_c('tr',[_c('td',[_vm._v(_vm._s(item.eventType))]),_c('td',[_vm._v(_vm._s(item.coverage))]),_c('td',[_vm._v(_vm._s(item.genre))]),_c('td',[_vm._v(_vm._s(item.season))]),_c('td',[_vm._v(_vm._s(item.olyply))]),_c('td',[_c('a',{staticClass:"org-link",attrs:{"href":item.lkWebsite,"target":"_blank"}},[_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-link-variant")]),_vm._v(" "+_vm._s(item.orgName)+" ")],1)]),_c('td',[(item.lkWebsite != '')?_c('a',{staticClass:"org-link",attrs:{"href":item.lkWebsite,"target":"_blank"}},[_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-link-variant")]),_vm._v(" ORG COMMITEE ")],1):(item.lkAgenda != '')?_c('a',{staticClass:"org-link",attrs:{"href":item.lkAgenda,"target":"_blank"}},[_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-link-variant")]),_vm._v(" "+_vm._s(item.sptName)+" AGENDA ")],1):_c('a',{staticClass:"org-link",attrs:{"href":item.lkWebsite,"target":"_blank"}},[_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-link-variant")]),_vm._v(" "+_vm._s(item.orgName)+" WEBSITE ")],1)])])])])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }