<template>
    <v-form class="form-left" @submit.prevent="searchSubmit">
        <v-row class="mt-4 no-gutters">                        
            <v-col class="pl-0 pr-0" cols="12" md="12">
                <Searcheventinidate />
            </v-col>
        </v-row>
        <v-row class="my-2 no-gutters">
            <v-col class="pl-0 pr-0" cols="12" md="12">
                <Searcheventfindate />
            </v-col>
        </v-row>
        <v-row class="my-2 no-gutters">
            <v-col class="px-0" cols="12" md="12">
                <Searcheventyear />
            </v-col>
        </v-row>
        <v-row class="my-2 no-gutters">
            <v-col class="px-0" cols="12" md="12">
                <Searcheventsport />
            </v-col>
        </v-row>
        <v-row class="my-2 no-gutters">
            <v-col class="px-0" cols="12" md="12">
                <Searcheventhostcountry />
            </v-col>
        </v-row>
        <v-row class="my-2 no-gutters">
            <v-col class="px-0" cols="12" md="12">
                <Searcheventlivestream />
            </v-col>
        </v-row>
        <v-row class="adv-row my-2 no-gutters">
            <v-col class="adv-menu-left px-0" cols="12" md="12">
                <Advancedsearch />
            </v-col>
        </v-row>
        <v-row class="adv-row my-2 no-gutters">
            <v-col class="adv-menu-left px-0" cols="12" md="12">
                <Homeclearform />
            </v-col>
        </v-row>
        <v-row class="mt-2 no-gutters">
            <v-col class="px-0" cols="12"  md="12">
                <Homesubmitbtn />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Searcheventinidate from '@/components/searchevent/Searcheventinidate'
import Searcheventfindate from '@/components/searchevent/Searcheventfindate'
import Searcheventyear from '@/components/searchevent/Searcheventyear'
import Searcheventsport from '@/components/searchevent/Searcheventsport'
import Searcheventhostcountry from '@/components/searchevent/Searcheventhostcountry'
import Searcheventlivestream from '@/components/searchevent/Searcheventlivestream'
import Advancedsearch from '@/components/advancedsearch/Advancedsearch'
import Homeclearform from '@/components/home/Homeclearform'
import Homesubmitbtn from '@/components/home/Homesubmitbtn'

export default {
    components: {
        Searcheventinidate,
        Searcheventfindate,
        Searcheventyear,
        Searcheventsport,
        Searcheventhostcountry,
        Searcheventlivestream,
        Advancedsearch,
        Homeclearform,
        Homesubmitbtn
    },
    computed: {
        ...mapState( 'searchevent', [ 'searchparams' ] ),
    },
    methods: {
        ...mapActions( 'searchevent', [ 'fetchEvents' ]),
        searchSubmit() {
            /*
            let params = {
                //txtsearch: this.searchparams.txtsearch,
                txtsearch: (this.searchparams.txtsearch != '') ? this.searchparams.txtsearch : [],
                year: (this.searchparams.year != '') ? this.searchparams.year : [],
                //inidate: this.searchparams.inidate,
                inidate: (this.searchparams.inidate != '') ? this.searchparams.inidate : [],
                //findate: this.searchparams.findate,
                findate: (this.searchparams.findate != '') ? this.searchparams.findate : [],
                sport: (this.searchparams.sport != '') ? this.searchparams.sport : [],
                coverage: (this.searchparams.coverage != '') ? this.searchparams.coverage : [],
                hostcountry: (this.searchparams.hostcountry != '') ? this.searchparams.hostcountry : [],
                sportorg: (this.searchparams.sportorg != '') ? this.searchparams.sportorg : [],
                //intfed: (this.searchparams.intfed != '') ? this.searchparams.intfed : [],
                eventtype: (this.searchparams.eventtype != '') ? this.searchparams.eventtype : [],
                season: (this.searchparams.season != '') ? this.searchparams.season : [],
                olyply: (this.searchparams.olyply != '') ? this.searchparams.olyply : [],
                genre: (this.searchparams.genre != '') ? this.searchparams.genre : [],
                livestream: (this.searchparams.livestream != '') ? this.searchparams.livestream : [],
                ticket: (this.searchparams.ticket != '') ? this.searchparams.ticket : []
            }*/
            this.fetchEvents(this.searchparams)
        },
    }
}
</script>

<style>    

    .v-navigation-drawer__border {
        width: 0 !important;    
    }

    .adv-menu-left {
        text-align: center;        
    }

    .adv-row {
        margin-bottom: 56px;
    }

    .fm-btn-menuleft {
        color: white !important;
    }

</style>