<template>
   <v-text-field
        label="City"
        v-model="isOrgCity"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'organisation', [ 'orgparams' ] ),
        isOrgCity: {
            get() { return this.orgparams.adrCity },
            set(item) { this.upParamsAdrcity(item) }
        }
    },
    methods: {
        ...mapMutations( 'organisation', [ 'upParamsAdrcity' ] )
    }
}
</script>

<style>

</style>