<template>
    <v-color-picker
        v-model="isEventColor"
        dot-size="20"
        hide-canvas
        hide-inputs
        hide-mode-switch
        hide-sliders
        show-swatches
        swatches-max-height="150"
        class="collor-picker-new"
    ></v-color-picker>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'event', [ 'eventparams' ] ),
        isEventColor: {
            get() { return this.eventparams.color },
            set(item) { this.upParamsColor(item) }
        }
    },
    methods: {
        ...mapMutations( 'event', [ 'upParamsColor' ] )
    }
}
</script>

<style>

</style>