<template>
    <v-dialog v-model="dialog" width="80%" height="500px">
        <template v-slot:activator="{ on }">
            <v-btn 
                class="adv-link"
                v-on="on"
                elevation="0"
                text
                color="primary"
                small
            >
                Advanced Search
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                Advanced Search
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row class="d-flex justify-space-between">
                        <v-col cols="12" sm="6">
                            <Advsearchcoverage />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <Advsearchsportorgs />
                        </v-col>
                        <!--
                        <v-col cols="12" sm="4">
                            <Advsearchintfed />
                        </v-col> 
                        -->                       
                    </v-row>
                    <v-row class="d-flex justify-space-between">
                        <v-col cols="12" sm="6" md="2">
                            <Advsearcheventtype />
                        </v-col>
                        <v-col cols="12" sm="6" md="2">
                            <Advsearchseason />
                        </v-col>
                        <v-col cols="12" sm="6" md="2">
                            <Advsearcholyply />
                        </v-col>
                        <v-col cols="12" sm="6" md="2">
                            <Advsearchgenre />
                        </v-col>
                        <v-col cols="12" sm="6" md="2">
                            <Advsearchticket />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
//import { mapState, mapMutations } from 'vuex'
import Advsearchcoverage from '@/components/advancedsearch/Advsearchcoverage'
import Advsearchsportorgs from '@/components/advancedsearch/Advsearchsportorgs'
//import Advsearchintfed from '@/components/advancedsearch/Advsearchintfed'
import Advsearcheventtype from '@/components/advancedsearch/Advsearcheventtype'
import Advsearchseason from '@/components/advancedsearch/Advsearchseason'
import Advsearcholyply from '@/components/advancedsearch/Advsearcholyply'
import Advsearchgenre from '@/components/advancedsearch/Advsearchgenre'
import Advsearchticket from '@/components/advancedsearch/Advsearchticket'

export default {
    components: {
        Advsearchcoverage,
        Advsearchsportorgs,
        //Advsearchintfed,
        Advsearcheventtype,
        Advsearchseason,
        Advsearcholyply,
        Advsearchgenre,
        Advsearchticket
    },
    data() {
        return {
            dialog: false
        }
    },
}
</script>

<style>

    .v-label {
        font-size: 14px;
    }
    
    .adv-link {
        font-size: 14px;
    }

</style>