<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="light-blue darken-3 py-2" @click="clearEveData" rounded dark>
                <v-icon left>mdi-plus</v-icon>Create
            </v-btn>
        </template>
        <v-card>
            <v-form @submit.prevent="eveAddSubmit">
                <v-card-title class="light-blue darken-3" dark>
                    <span class="headline">Create a New Event</span>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pa-8">
                    <v-row>
                        <v-col class="pr-8" cols="12" md="8"> 
                            <v-row>
                                <v-col><Eventtitle /></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6"><Eventtournname /></v-col>
                                <v-col cols="12" sm="3"><Eventinidate /></v-col>
                                <!-- <v-col><Eventinitime /></v-col> -->
                                <v-col cols="12" sm="3"><Eventenddate /></v-col>
                                <!-- <v-col><Eventendtime /></v-col> -->
                            </v-row>
                            <v-row>
                                <v-col><Eventdesc /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Eventlocal /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Eventcity /></v-col>
                                <v-col><Eventcountry /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Eventsport /></v-col>
                                <v-col><Eventorgowner /></v-col>
                                <v-col><Eventorgexec /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Eventtype /></v-col>
                                <v-col><Eventcoverage /></v-col>
                                <v-col><Eventgenre /></v-col>
                                <v-col><Eventticket /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Eventlkticket /></v-col>
                                <v-col><Eventlkstreamos /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Eventlkstreampp /></v-col>
                                <v-col><Eventlkstreamfb /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Eventlkstreamyt /></v-col>
                                <v-col><Eventlkstreamtw /></v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pl-8" cols="12" md="4">
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <p class="py-0 my-0">Color</p>
                                    <Eventcolor />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <p class="py-0 my-0">Owner</p>
                                    <h3>{{ $auth.user.name }}</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-card-actions class="px-0">
                                        <v-btn
                                            type="submit" 
                                            color="light-blue darken-3 px-4" 
                                            rounded
                                            dark
                                            @click="dialog = false"
                                        >
                                            Create
                                        </v-btn>
                                    </v-card-actions>    
                                </v-col>
                            </v-row>
                            <v-row>
                                {{eventparams}}
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Eventtitle from './Eventtitle'
import Eventtournname from './Eventtournname'
import Eventinidate from './Eventinidate'
//import Eventinitime from './Eventinitime'
import Eventenddate from './Eventenddate'
//import Eventendtime from './Eventendtime'
import Eventlocal from './Eventlocal'
import Eventcountry from './Eventcountry'
import Eventcity from './Eventcity'
import Eventdesc from './Eventdesc'
import Eventsport from './Eventsport'
import Eventorgowner from './Eventorgowner'
import Eventorgexec from './Eventorgexec'
import Eventtype from './Eventtype'
import Eventcoverage from './Eventcoverage'
import Eventgenre from './Eventgenre'
import Eventticket from './Eventticket'
import Eventlkticket from './Eventlkticket'
import Eventlkstreamos from './Eventlkstreamos'
import Eventlkstreampp from './Eventlkstreampp'
import Eventlkstreamfb from './Eventlkstreamfb'
import Eventlkstreamyt from './Eventlkstreamyt'
import Eventlkstreamtw from './Eventlkstreamtw'
import Eventcolor from './Eventcolor'
import Eventcal from './Eventcal'

export default {
    components: {
        Eventtitle,
        Eventtournname,
        Eventinidate,
        //Eventinitime,
        Eventenddate,
        //Eventendtime,
        Eventcountry,
        Eventcity,
        Eventlocal,
        Eventdesc,
        Eventsport,
        Eventorgowner,
        Eventorgexec,
        Eventtype,
        Eventcoverage,
        Eventgenre,
        Eventticket,
        Eventlkticket,
        Eventlkstreamos,
        Eventlkstreampp,
        Eventlkstreamfb,
        Eventlkstreamyt,
        Eventlkstreamtw,
        Eventcolor,
        Eventcal
    },
    data: () => ({
        dialog: false
    }),
    mounted() {
        this.upParamsUidOwner(this.$auth.user.sub.slice(6)),
        this.upParamsInsertedby(this.$auth.user.sub.slice(6)),
        this.upParamsUpdatedby(this.$auth.user.sub.slice(6))
    },
    computed: {
        ...mapState( 'event', [ 'eventparams' ] )
    },
    methods: {
        ...mapActions( 'event', [ 'addEvent' ] ),
        ...mapMutations( 'event', [ 'upParamsInsertedby', 'upParamsUpdatedby', 'upParamsUidOwner' ] ),
        eveAddSubmit() {
            this.addEvent(this.eventparams)
        },
        clearEveData() {
            this.eventparams.year = '',
            this.eventparams.dtBegin = '',
            this.eventparams.dtEnd = '',
            this.eventparams.title = '',
            this.eventparams.calId = null,
            this.eventparams.aptDesc = '',
            this.eventparams.aptLocal = '',
            this.eventparams.color = '#1E88E5',
            this.eventparams.sptId = null,
            this.eventparams.sptName = '',
            this.eventparams.city = '',
            this.eventparams.ctrId = null,
            this.eventparams.countryName = '',
            this.eventparams.countryFlag = '',
            this.eventparams.ticket = '',
            this.eventparams.lkTicket = '',
            this.eventparams.lkStreamOs = '',
            this.eventparams.lkStreamPp = '',
            this.eventparams.lkStreamFb = '',
            this.eventparams.lkStreamYt = '',
            this.eventparams.lkStreamTw = '',
            this.eventparams.eventType = '',
            this.eventparams.coverage = '',
            this.eventparams.genre = '',
            this.eventparams.orgOwner = '',
            this.eventparams.acrOwner = '',
            this.eventparams.orgExec = '',
            this.eventparams.acrExec = ''
        },
    },
}
</script>

<style>
    .headline {
        color: white;
    }
    .collor-picker-new {
        max-width: none !important;
    }
</style>