<template>
    <v-select
        class="bd-rad"
        v-model="isYears"
        :items="years"
        placeholder="Years"
        append-icon="mdi-chevron-down"
        outlined
        dense
        hide-details
        :menu-props="{ maxHeight: '400' }"
        multiple
    >
        <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
                <v-list-item-action>
                    <v-icon :color="searchparams.sport.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Select All
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
                {{ item }}
            </span>
            <span
                v-if="index === 1"
                class="grey--text text-caption pl-1"
            >
                (+{{ searchparams.sport.length - 1 }} others)
            </span>
        </template>
    </v-select>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'years', 'searchparams' ] ),
        isYears: {
            get() { return this.searchparams.year },
            set(item) { this.upParamsYr(item) }
        },
        selAllYears () {
            return this.searchparams.year.length === this.years.length
        },
        selSomeYears () {
            return this.searchparams.year.length > 0 && !this.selAllSports
        },
        icon () {
            if (this.selAllYears) return 'mdi-close-box'
            if (this.selSomeYears) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    mounted() {
        this.fetchYears()
    },
    methods: {
        ...mapActions( 'searchevent', [ 'fetchYears' ]),
        ...mapMutations( 'searchevent', [ 'upParamsYr' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllYears) {
                this.searchparams.year = []
            } else {
                this.searchparams.year = this.years.slice()
            }
            })
        },
    },
}
</script>

<style>

    .bd-rad {
        border-radius: 50px;
        font-size: 14px;
    }

</style>