<template>
    <v-autocomplete
        v-model="isAdrCountry"
        :items="countries"
        item-value="id"
        item-text="countryName"
        dense
        filled
        hide-details
        label="Country"
    ></v-autocomplete>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    mounted() {
        this.fetchCountries()
        this.iniAdrCountry()
    },
    computed: {
        ...mapState( 'organisation', [ 'orgparams', 'countries' ] ),
        isAdrCountry: {
            get() { return this.orgparams.adrCountry },
            set(item) { this.upParamsAdrcountry(item) }
        }
    },
    methods: {
        ...mapActions( 'organisation', [ 'fetchCountries' ] ),
        ...mapMutations( 'organisation', [ 'upParamsAdrcountry' ] ),
        iniAdrCountry() {
            this.isAdrCountry = ''
        },
    }
}
</script>

<style>

</style>