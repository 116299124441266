<template>
  <v-app>
    <MainMenu />
    <MainAppBar />
    <v-main>
        <v-container fluid>
            <v-row class="fill-height">
                <CalLeftMenu />
                <v-col class="py-0" cols="12" md="10">
                    <v-sheet height="64">
                        <v-toolbar flat class="cal-header">
                            <ApptCreate />
                            <v-btn outlined class="mx-4" color="grey darken-2" @click="setToday">
                                Today
                            </v-btn>
                            <v-btn fab text small color="grey darken-2" @click="prev">
                                <v-icon small>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn fab text small color="grey darken-2" @click="next">
                                <v-icon small>mdi-chevron-right</v-icon>
                            </v-btn>
                            <v-toolbar-title v-if="$refs.calendar">
                                {{ $refs.calendar.title }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-menu bottom right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                        <span>{{ typeToLabel[type] }}</span>
                                        <v-icon right>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="type = 'day'">
                                        <v-list-item-title>Day</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="type = 'week'">
                                        <v-list-item-title>Week</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="type = 'month'">
                                        <v-list-item-title>Month</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="type = '4day'">
                                        <v-list-item-title>4 days</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>
                    </v-sheet>
                    <v-sheet height="600">
                        <v-calendar
                            ref="calendar"
                            v-model="focus"
                            color="primary"
                            :events="getAppointments"
                            :event-color="getEventColor"
                            :type="type"
                            :event-ripple="false"
                            @click:event="showEvent"
                            @click:more="viewDay"
                            @click:date="viewDay"
                            @change="updateRange"
                            @mousedown:event="startDrag"
                            @mousedown:time="startTime"
                            @mousemove:time="mouseMove"
                            @mouseup:time="endDrag"
                            @mouseleave.native="cancelDrag"
                            
                        >
                            <template v-slot:day-body="{ date, week }">
                                <div
                                    class="v-current-time"
                                    :class="{ first: date === week[0].date }"
                                    :style="{ top: nowY }"
                                ></div>
                            </template>
                            <template v-slot:event="{ event, timed, eventSummary }">
                                <div
                                    class="v-event-draggable"
                                    v-html="eventSummary()"
                                ></div>
                                <div
                                    v-if="timed"
                                    class="v-event-drag-bottom"
                                    @mousedown.stop="extendBottom(event)"
                                ></div>
                            </template>
                        </v-calendar>
                        <v-menu
                            v-model="selectedOpen"
                            :close-on-content-click="false"
                            :activator="selectedElement"
                            offset-x
                        >
                            <v-card color="grey lighten-4" min-width="350px" flat>
                                <v-toolbar :color="selectedEvent.color" dark dense>
                                    <ApptUpdate :apptid="selectedEvent.id" @closeAppt="selectedOpen = false"/>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="delAppt(selectedEvent.id)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="selectedOpen = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ selectedEvent.name }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-clock-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <h5>{{ selectedEvent.start }}</h5>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex' 
import MainMenu from '@/components/MainMenu'
import MainAppBar from '@/components/MainAppBar'
import CalLeftMenu from '@/components/viewcalendar/Calendarleftmenu'
import ApptCreate from '@/components/viewcalendar/Apptcreate'
import ApptUpdate from '@/components/viewcalendar/Apptupdate'
import appData from '../assets/appt.json'

export default {
    name: 'Calendar',
    components: {
        MainMenu,
        MainAppBar,
        CalLeftMenu,
        ApptCreate,
        ApptUpdate
    },
    data: () => ({
        date: new Date().toISOString().substr(0, 10),
        focus: '',
        type: 'week',
        typeToLabel: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            '4day': '4 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        ready: false,
        
        dragEvent: null,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,
    }),
     computed: {
        ...mapGetters([ 'getAppointments' ]),
        ...mapState([ 'calsel' ]),
        cal () {
            return this.ready ? this.$refs.calendar : null
        },
        nowY () {
            return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
        },
    },
    mounted () {
        this.$refs.calendar.checkChange()
        this.ready = true
        this.scrollToTime()
        this.updateTime()
        this.userid = this.$auth.user.sub.slice(6)
        this.fetchCalendars(this.userid)
        //const cals = { calendarid: this.calsel }
        //this.fetchAppointments(cals)
        //console.log(JSON.stringify(this.calsel))
        
    },
    methods: {
        ...mapActions([ 'fetchAppointments', 'fecthCalendars', 'delAppointment' ]),
        /*
        updateRange ({ start, end }) {
            const events = []   
            this.start = start
            this.end = end
            this.events = this.getAppointments
            console.log(JSON.stringify(this.events))
        },
        */
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },
        setToday () { this.focus = '' },
        prev () { this.$refs.calendar.prev() },
        next () { this.$refs.calendar.next() },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                setTimeout(() => {
                    this.selectedOpen = true
                }, 10)
            }
            if (this.selectedOpen) {
                this.selectedOpen = false
                setTimeout(open, 10)
            } else {
                open()
            }
            nativeEvent.stopPropagation()
        },

        delAppt(apptid) {
            this.delAppointment(apptid).then(() => {
                const calssel = {calendarid: this.calsel}
                this.fetchAppointments(calssel)
                this.selectedOpen = false
            })
        },

        // Calendar Time Line
        getCurrentTime () {
            return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
        },
        scrollToTime () {
            const time = this.getCurrentTime()
            const first = Math.max(0, time - (time % 30) - 30)

            this.cal.scrollToTime(first)
        },
        updateTime () {
            setInterval(() => this.cal.updateTimes(), 60 * 1000)
        },

        //Drag and Drop, Click to Create
        startDrag ({ event, timed }) {
            if (event && timed) {
                this.dragEvent = event
                this.dragTime = null
                this.extendOriginal = null
            }
        },
        startTime (tms) {
            const mouse = this.toTime(tms)

            if (this.dragEvent && this.dragTime === null) {
                const start = this.dragEvent.start

                this.dragTime = mouse - start
            } else {
                this.createStart = this.roundTime(mouse)
                this.createEvent = {
                    name: '(No Title)',//`Event #${this.getAppointments.length}`,
                    color: 'primary', //this.rndElement(this.colors),
                    start: this.createStart,
                    end: this.createStart,
                    timed: true,
            }
            this.getAppointments.push(this.createEvent)
            }
        },
        extendBottom (event) {
            this.createEvent = event
            this.createStart = event.start
            this.extendOriginal = event.end
        },
        mouseMove (tms) {
            const mouse = this.toTime(tms)

            if (this.dragEvent && this.dragTime !== null) {
                const start = this.dragEvent.start
                const end = this.dragEvent.end
                const duration = end - start
                const newStartTime = mouse - this.dragTime
                const newStart = this.roundTime(newStartTime)
                const newEnd = newStart + duration

                this.dragEvent.start = newStart
                this.dragEvent.end = newEnd
            } else if (this.createEvent && this.createStart !== null) {
                const mouseRounded = this.roundTime(mouse, false)
                const min = Math.min(mouseRounded, this.createStart)
                const max = Math.max(mouseRounded, this.createStart)

                this.createEvent.start = min
                this.createEvent.end = max
            }
        },
        endDrag () {
            this.dragTime = null
            this.dragEvent = null
            this.createEvent = null
            this.createStart = null
            this.extendOriginal = null
        },
        cancelDrag () {
            if (this.createEvent) {
                if (this.extendOriginal) {
                    this.createEvent.end = this.extendOriginal
                } else {
                    const i = this.events.indexOf(this.createEvent)
                    if (i !== -1) {
                    this.events.splice(i, 1)
                    }
                }
            }
            this.createEvent = null
            this.createStart = null
            this.dragTime = null
            this.dragEvent = null
        },
        roundTime (time, down = true) {
            const roundTo = 15 // minutes
            const roundDownTime = roundTo * 60 * 1000
            return down
            ? time - time % roundDownTime
            : time + (roundDownTime - (time % roundDownTime))
        },
        toTime (tms) {
            return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
        },
        getEventColor (event) {
            const rgb = parseInt(event.color.substring(1), 16)
            const r = (rgb >> 16) & 0xFF
            const g = (rgb >> 8) & 0xFF
            const b = (rgb >> 0) & 0xFF
            return event === this.dragEvent
            ? `rgba(${r}, ${g}, ${b}, 0.7)`
            : event === this.createEvent
                ? `rgba(${r}, ${g}, ${b}, 0.7)`
                : event.color
        },
    },
}
</script>

<style lang="scss">

    .cal-header > .v-toolbar__content {
        padding: 0 !important;
    }

    .v-current-time {
        height: 2px;
        background-color: #ea4335;
        position: absolute;
        left: -1px;
        right: 0;
        pointer-events: none;

        &.first::before {
            content: '';
            position: absolute;
            background-color: #ea4335;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-top: -5px;
            margin-left: -6.5px;
        }
    }

    .v-event-draggable {
        padding-left: 6px;
    }

    .v-event-timed {
        user-select: none;
        -webkit-user-select: none;
    }

    .v-event-drag-bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4px;
        height: 4px;
        cursor: ns-resize;

        &::after {
            display: none;
            position: absolute;
            left: 50%;
            height: 4px;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            width: 16px;
            margin-left: -8px;
            opacity: 0.8;
            content: '';
        }

        &:hover::after {
            display: block;
        }
    }

</style>