<template>
    <v-autocomplete
        v-model="isOrgUp"
        :items="orgsUp"
        item-text="orgAcronymName"
        item-value="id"
        filled
        hide-details
        label="User Organisation"
    ></v-autocomplete>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    mounted() {
        this.fetchOrgs()
    },
    computed: {
        ...mapState( 'user', [ 'orgsUp', 'userparams' ] ),
        isOrgUp: {
            get() { return this.userparams.user_metadata.orgUp },
            set(item) { this.upParamsOrgUp(item) }
        }
    },
    methods: {
        ...mapActions( 'user', [ 'fetchOrgs' ] ),
        ...mapMutations( 'user', [ 'upParamsOrgUp' ] ),
    }
}
</script>

<style>

</style>