<template>
    <v-autocomplete
        v-model="isSports"
        :items="sports"
        item-value="id"
        item-text="sptName"
        dense
        filled
        hide-details
        label="Sports"
        append-icon="mdi-chevron-down"
        @change="onChange"
    ></v-autocomplete>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    mounted() {
        this.fetchSports()
    },
    computed: {
        ...mapState( 'event', [ 'eventparams', 'sports' ] ),
        isSports: {
            get() { return this.eventparams.sptId },
            set(item) { this.upParamsSport(item) }
        }
    },
    methods: {
        ...mapActions( 'event', [ 'fetchSports'] ),
        ...mapMutations( 'event', [ 'upParamsSport', 'upParamsSportName' ] ),
        onChange(item) {
            let sptitem = this.sports.find(sptitem => sptitem.id === item);
            this.upParamsSportName(sptitem.sptName)
        }
    }
}
</script>

<style>

</style>