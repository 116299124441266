<template>
    <v-toolbar flat>
        <v-toolbar-title>Organisation Management</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <OrgCreate />
    </v-toolbar>
</template>

<script>
import OrgCreate from '@/components/organisation/Orgcreate'

export default {
    name: 'Eventtoolbar',
    components: {
        OrgCreate
    }
}
</script>

<style>

</style>