<template>
    <v-data-table
        :headers="headers"
        :items="searchEvents"
        :items-per-page="50"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        expand-icon="mdi-chevron-down"
        show-expand
        item-key="title"
        class="sptf-search-table elevation-1"
    >
        <template v-slot:[`item.year`]="{ item }">
            <v-chip class="year-chip" :color="getColor(item.year)" dark>{{ item.year }}</v-chip>
        </template>
        
        <template v-slot:[`item.period`]="{ item }">
            <div class="totperiod">
                <div class="iniperiod">
                    <div>{{item.dtBegin.slice(8,10)}} - {{getMonth(item.dtBegin.slice(5,7))}}</div>
                </div>
                <div class="endperiod">
                    <div>{{item.dtEnd.slice(8,10)}} - {{getMonth(item.dtEnd.slice(5,7))}}</div>
                </div>
            </div>
        </template>

        <template v-slot:[`item.calName`]="{ item }">
            <strong>{{item.calName}}</strong>
            <br>{{item.title.substr(0, 40) + '...'}}
        </template>

        <template v-slot:[`item.city`]="{ item }">
            <a class="city-link" :href="'https://www.google.de/maps/place/'+item.city" target="_blank">
                <v-icon right small>mdi-map-marker</v-icon>
                {{item.city}}
            </a>
        </template>

        <template v-slot:[`item.country`]="{ item }">
            <v-img
                class="country-flag"
                :src="require('@/assets/flags/'+item.countryFlag.toLowerCase()+'.png')"
                aspect-ratio="0.7"
                contain
                :title="item.countryName"
            ></v-img>
        </template>

        <template v-slot:[`item.ticket`]="{ item }">                
            <v-chip class="ticket-chip" v-if="item.ticket != ''" :color="getTicketColor(item.ticket)" small dark>{{ item.ticket.toUpperCase() }}</v-chip>
            <v-chip class="ticket-chip" v-else :color="getTicketColor(item.ticket)" small dark>NOTDEF</v-chip>
        </template>

        <template v-slot:[`item.livestream`]="{ item }">
            <span class="lsicon-group">
                <v-icon class="px-1" v-if="item.lkStreamOs != ''" :href="item.lkStreamOs" target="_blank" color="yellow darken-2">fab fa-font-awesome</v-icon>
                <v-icon class="px-1" v-if="item.lkStreamPp != ''" :href="item.lkStreamPp" target="_blank" color="black">fab fa-pied-piper-pp</v-icon>
                <v-icon class="px-1"  v-if="item.lkStreamFb != ''" :href="item.lkStreamFb" target="_blank" color="blue">fab fa-facebook-square</v-icon>
                <v-icon class="px-1"  v-if="item.lkStreamYt != ''" :href="item.lkStreamYt" target="_blank" color="red">fab fa-youtube-square</v-icon>
                <v-icon class="px-1"  v-if="item.lkStreamTw != ''" :href="item.lkStreamTw" target="_blank" color="blue">fab fa-twitter-square</v-icon>
            </span>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
            <td class="exp-tb pa-0" :colspan="headers.length">
                <v-simple-table>
                    <thead class="exp-theader">
                        <tr>
                            <th>Event Type</th>
                            <th>Coverage</th>
                            <th>Genre</th>
                            <th>Sport Season</th>
                            <th>Oly/Ply</th>
                            <th>Sport Organisation</th>
                            <th>Event OC</th>
                        </tr>
                    </thead>
                    <tbody class="exp-tbody">
                        <tr>
                            <td>{{ item.eventType }}</td>
                            <td>{{ item.coverage }}</td>
                            <td>{{ item.genre }}</td>
                            <td>{{ item.season }}</td>
                            <td>{{ item.olyply }}</td>
                            <td>
                                <a class="org-link" :href="item.lkWebsite" target="_blank">
                                    <v-icon right small>mdi-link-variant</v-icon>
                                    {{ item.orgName }}
                                </a>
                            </td>
                            <td>
                                <a class="org-link" v-if="item.lkWebsite != ''" :href="item.lkWebsite" target="_blank">
                                    <v-icon right small>mdi-link-variant</v-icon>
                                    ORG COMMITEE
                                </a>
                                <a class="org-link" v-else-if="item.lkAgenda != ''" :href="item.lkAgenda" target="_blank">
                                    <v-icon right small>mdi-link-variant</v-icon>
                                    {{ item.sptName }} AGENDA
                                </a>
                                <a class="org-link" v-else :href="item.lkWebsite" target="_blank">
                                    <v-icon right small>mdi-link-variant</v-icon>
                                    {{ item.orgName }} WEBSITE
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </td>
        </template> 
    </v-data-table>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Searcheventdatatable',
    data () {
        return {
            drawerLeft: false,
            headers: [
                { text: '', align: 'center', sortable: false, value: 'data-table-expand' },
                { text: 'Year', align: 'center', sortable: false, value: 'year' },
                { text: 'Period', align: 'center', sortable: false, fixed: true, value: 'period' },
                { text: 'Event Type / Event Name', align: 'left', sortable: false, value: 'calName' },
                { text: 'Sport', align: 'center', sortable: false, value: 'sptName' },
                { text: 'City', align: 'center', sortable: false, value: 'city' },
                { text: 'Country', align: 'center', sortable: false, value: 'country' },
                { text: 'Ticket', align: 'center', sortable: false, value: 'ticket' },
                { text: 'Web Live Streaming', align: 'left', sortable: false, value: 'livestream' },
            ],
            expanded: [],
            singleExpand: true,
        }
    },
    mounted() {
        this.fetchEvents(this.searchparams)
    },
    computed: {
        ...mapGetters( 'searchevent', [ 'searchEvents' ] ),
        ...mapState( 'searchevent', [ 'searchparams' ] )
    },
    methods: {
        ...mapActions( 'searchevent', [ 'fetchEvents' ]),
        getColor(dtBegin) {
            if (Number(dtBegin)%2 == 0) return 'light-blue darken-1'            
            else return 'light-blue darken-3'
        },
        getMonth(datep) {
            switch (datep) {
                case '01': return 'Jan'
                case '02': return 'Feb'
                case '03': return 'Mar'
                case '04': return 'Apr'
                case '05': return 'May'
                case '06': return 'Jun'
                case '07': return 'Jul'
                case '08': return 'Aug'
                case '09': return 'Sep'
                case '10': return 'Oct'
                case '11': return 'Nov'
                case '12': return 'Dec'
            }
        },
        getTicketColor(ticket) {
            switch (ticket) {
                case 'Buy': return 'blue lighten-3'
                case 'Free': return 'green lighten-2'
                case '': return 'yellow darken-2'
            }
        }
    }

}
</script>

<style>

</style>