<template>
    <v-container class="pa-0">
        <v-row class="d-flex align-center">
            <v-col cols="12" md="2" class="pr-0">Date of Birth:</v-col>
            <v-col cols="12" md="2" class="px-0">
                <v-select
                    v-model="isDtBirthDay"
                    :items="days"
                    label="Day"
                    append-icon="mdi-chevron-down"
                    filled
                    hide-details
                ></v-select>
            </v-col>
            <v-col cols="12" md="5" class="px-2">
                <v-select
                    v-model="isDtBirthMonth"
                    :items="months"
                    item-text="monthName"
                    item-value="num"
                    label="Month"
                    append-icon="mdi-chevron-down"
                    filled
                    hide-details
                ></v-select>
            </v-col>
            <v-col cols="12" md="3" class="pl-0">
                <v-select
                    v-model="isDtBirthYear"
                    :items="years"
                    label="Year"
                    append-icon="mdi-chevron-down"
                    filled
                    hide-details
                ></v-select>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data: () => ({
        months: [
            {"num": "01", "monthName": "January"}, 
            {"num": "02", "monthName": "February"}, 
            {"num": "03", "monthName": "March"}, 
            {"num": "04", "monthName": "April"}, 
            {"num": "05", "monthName": "May"}, 
            {"num": "06", "monthName": "June"}, 
            {"num": "07", "monthName": "July"}, 
            {"num": "08", "monthName": "August"}, 
            {"num": "09", "monthName": "September"}, 
            {"num": "10", "monthName": "October"}, 
            {"num": "11", "monthName": "November"}, 
            {"num": "12", "monthName": "December"}
        ],
        dtbirth: [],
        day: '',
        month: '',
        year: ''
    }),
    computed: {
        ...mapState( 'user', [ 'userparams' ] ),
        years() {
            const year = new Date().getFullYear()
            const listyears = Array.from({length: year - 1900}, (value, index) => 1901 + index)
            return listyears.sort((a,b)=>a.weight<b.weight?1:-1)
        },
        days() {
            return Array.from({length: 31}, (_, i) => i + 1)
        },
        isDtBirthDay: {
            get() { 
                let date = this.userparams.user_metadata.dateBirth
                let dateday = date.slice(8,10)
                return parseInt(dateday, 10)
            },
            set(item) {
                let dtostr = item.toString()
                if (item in [0,1,2,3,4,5,6,7,8,9]) {
                    dtostr = "0" + dtostr
                }
                //let day = {field: "day", val: dtostr}
                this.dtbirth[2] = dtostr
                console.log(this.dtbirth)
                //this.day = item
                
                if (this.dtbirth.length === 3) {
                    this.upParamsDateBirth(this.dtbirth.join('-'))
                }
            }
        },
        isDtBirthMonth: {
            get() { 
                let date = this.userparams.user_metadata.dateBirth
                return date.slice(5,7) 
            },
            set(item) {
                //let month = {field: "month", val: item}
                this.dtbirth[1] = item
                console.log(this.dtbirth)
                //this.month = item
                
                if (this.dtbirth.length === 3) {
                    this.upParamsDateBirth(this.dtbirth.join('-'))
                }
            }
        },
        isDtBirthYear: {
            get() { 
                let date = this.userparams.user_metadata.dateBirth
                let dateyear = date.slice(0,4)
                return parseInt(dateyear, 10)
            },
            set(item) {
                let ytostr = item.toString()
                //let year = {field: "year", val: ytostr}
                this.dtbirth[0] = ytostr
                console.log(this.dtbirth)
                //this.year = item
                
                if (this.dtbirth.length === 3) {
                    this.upParamsDateBirth(this.dtbirth.join('-'))
                }
            }
        },
    },
    methods: {
        ...mapMutations( 'user', [ 'upParamsDateBirth' ] ),
        onChange(inidate) {
            let dbyear = inidate.slice(0,4)
            this.upParamsDtBeginYear(dbyear)
        }
    },
}
</script>

<style>

</style>