import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
//import createPersistedState from 'vuex-persistedstate'
import * as searchevent from '@/store/modules/searchevent'
import * as user from '@/store/modules/user'
import * as event from '@/store/modules/event'
import * as organisation from '@/store/modules/organisation'
import * as sport from '@/store/modules/sport'
import * as calendar from '@/store/modules/calendar'

Vue.use(Vuex)

export default new Vuex.Store({ 
  /*plugins: [createPersistedState({
    paths: [ 'calsel', 'calendars', 'appointments', 'events' ]
  })],*/
  modules: {
    searchevent,
    user,
    event,
    organisation,
    sport,
    calendar
  },
  state: {
    calendars: [],
    calsel:[],
    appointments: [],
    calparams: {
      calid: '',
      calname: '',
      caldesc: '',
      calcolor: '',
      calowner: '',
      calshared: ''
    },
    apptparams: {
      apptid: '',
      apptdtins: '',
      apptdtbg: '',
      apptdtend: '',
      appttitle: '',
      apptdesc: '',
      apptlocal: '',
      apptcolor: '',
      apptrecur: '',
      apptcalid: '',
      apptowner: '',
      apptshared: ''
    }
  },
  getters: {
    getCalendars: state => state.calendars,
    getAppointments: state => state.appointments,
  },
  mutations: {
    setCalendars: (state, calendars) => (state.calendars = calendars),
    setCalSelected: (state, calsel) => (state.calsel = calsel),
    setOneCalendar: (state, calparams) => (state.calparams = calparams),
    newCalendar: (state, calparams) => state.calendars.unshift(calparams),
    removeCalendar: (state, id) => 
        (state.calendars = state.calendars.filter(cal => cal.CALENDAR_ID !== id)),
    updCalendar: (state, upcal) => {
      const index = state.calendars.findIndex(cal => cal.CALENDAR_ID === upcal.CALENDAR_ID)
      if (index !== -1) {
        state.calendars.splice(index, 1, upcal)
      }
    },
    updCalName: (state, upcalname) => (state.calparams.CALENDAR_NAME = upcalname),
    updCalDesc: (state, upcaldesc) => (state.calparams.CALENDAR_DESC = upcaldesc),
    updCalColor: (state, upcalcolor) => (state.calparams.CALENDAR_COLOR = upcalcolor),
    updCalSelected: (state, upcalsel) => (state.calsel = upcalsel),

    setAppointments: (state, arrapptscal) => (state.appointments = arrapptscal),
    setOneAppt: (state, apptparams) => (state.apptparams = apptparams),
    newAppointment: (state, apptparams) => state.appointments.unshift(apptparams),
    removeAppt: (state, id) => 
        (state.calendars = state.calendars.filter(cal => cal.CALENDAR_ID !== id)),
    updAppt: (state, upcal) => {
      const index = state.calendars.findIndex(cal => cal.CALENDAR_ID === upcal.CALENDAR_ID)
      if (index !== -1) {
        state.calendars.splice(index, 1, upcal)
      }
    },
    updApptDtbg: (state, upapptdtbg) => (state.apptparams.start = upapptdtbg),
    updApptDtbgTime: (state, upapptdtbgtime) => (state.apptparams.starttime = upapptdtbgtime),
    updApptDtend: (state, upapptdtend) => (state.apptparams.end = upapptdtend),
    updApptDtendTime: (state, upapptdtendtime) => (state.apptparams.endtime = upapptdtendtime),
    updApptTitle: (state, upappttitle) => (state.apptparams.title = upappttitle),
    updApptLocal: (state, upapptlocal) => (state.apptparams.local = upapptlocal),
    updApptDesc: (state, upapptdesc) => (state.apptparams.desc = upapptdesc),
    updApptColor: (state, upapptcolor) => (state.apptparams.color = upapptcolor),
    updApptCal: (state, upapptcal) => (state.apptparams.calid = upapptcal),

  },
  actions: {
    // Calendars
    async fetchCalendars( { commit }, id ) {
      let response = await axios.get(`/calendar/usr/${id}`)
      let arrcalsel = []
      let arrcalendars = response.data
      for (var i = 0; i < arrcalendars.length; i++) {
        if (arrcalendars[i].UID_SHARED == 'true') {
          arrcalsel.push(arrcalendars[i].CALENDAR_ID)
        }
      }
      commit('setCalendars', arrcalendars)
      commit('setCalSelected', arrcalsel)
    },

    async fetchOneCalendar( { commit }, id ) {
      let response = await axios.get(`/calendar/${id}`)
      let arronecalendar = response.data
      commit('setOneCalendar', arronecalendar)
    },

    async addCalendar( { commit }, parms) {
      let response = await axios.post('/calendar', parms )
      let arrnewcalendar = response.data
      commit('newCalendar', arrnewcalendar)
    },

    async delCalendar( { commit }, id ) {
      await axios.delete(`/calendar/${id}`)
      commit('removeCalendar', id)
    },

    async updCalendar( { commit }, upparms) {  
      let response = await axios.put(`/calendar/${upparms.CALENDAR_ID}`, upparms )
      commit('updCalendar', upparms)
    },

    //Appointments
    async fetchAppointments( { commit }, parms ) {
      //console.log(JSON.stringify(parms))
      let response = await axios.get(`/appointment`, { params: parms })
      let arrapptscal = response.data
      //console.log(JSON.stringify(arrapptscal))
      commit('setAppointments', arrapptscal)
    },

    async fetchOneAppointment( { commit }, id ) {
      let response = await axios.get(`/appointment/${id}`)
      let arroneappt = response.data
      commit('setOneAppt', arroneappt)
    },

    async addAppointment( { commit }, parms) {
      let response = await axios.post('/appointment', parms )
      let arrnewappt = response.data
      //console.log(parms)
      commit('newAppointment', arrnewappt)
    },

    async delAppointment( { commit }, id ) {
      await axios.delete(`/appointment/${id}`)
      commit('removeAppt', id)
    },

    async updAppointment( { commit }, upparms) {  
      let response = await axios.put(`/appointment/${upparms.APT_ID}`, upparms )
      commit('updAppt', upparms)
    },
  }
})
