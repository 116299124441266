var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('MainMenu'),_c('MainAppBar'),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height"},[_c('CalLeftMenu'),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10"}},[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{staticClass:"cal-header",attrs:{"flat":""}},[_c('ApptCreate'),_c('v-btn',{staticClass:"mx-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Today ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Day")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Week")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Month")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = '4day'}}},[_c('v-list-item-title',[_vm._v("4 days")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.getAppointments,"event-color":_vm.getEventColor,"type":_vm.type,"event-ripple":false},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange,"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startTime,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag($event)}},scopedSlots:_vm._u([{key:"day-body",fn:function(ref){
var date = ref.date;
var week = ref.week;
return [_c('div',{staticClass:"v-current-time",class:{ first: date === week[0].date },style:({ top: _vm.nowY })})]}},{key:"event",fn:function(ref){
var event = ref.event;
var timed = ref.timed;
var eventSummary = ref.eventSummary;
return [_c('div',{staticClass:"v-event-draggable",domProps:{"innerHTML":_vm._s(eventSummary())}}),(timed)?_c('div',{staticClass:"v-event-drag-bottom",on:{"mousedown":function($event){$event.stopPropagation();return _vm.extendBottom(event)}}}):_vm._e()]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":"","dense":""}},[_c('ApptUpdate',{attrs:{"apptid":_vm.selectedEvent.id},on:{"closeAppt":function($event){_vm.selectedOpen = false}}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.delAppt(_vm.selectedEvent.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.selectedOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.selectedEvent.name))])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock-outline")])],1),_c('v-list-item-content',[_c('h5',[_vm._v(_vm._s(_vm.selectedEvent.start))])])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }