<template>
    <v-autocomplete
        v-model="isCalOrgOwner"
        :items="orgs"
        item-text="orgAcronymName"
        item-value="id"
        filled
        hide-details
        label="Organisation Owner"
        @change="onChange"
    ></v-autocomplete>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    mounted() {
        this.fetchOrgs()
    },
    computed: {
        ...mapState( 'calendar', [ 'orgs', 'calparams' ] ),
        isCalOrgOwner: {
            get() { return this.calparams.orgId },
            set(item) { this.upParamsOrgid(item) }
        }
    },
    methods: {
        ...mapActions( 'calendar', [ 'fetchOrgs' ] ),
        ...mapMutations( 'calendar', [ 'upParamsOrgid', 'upParamsOrgacronymname' ] ),
        onChange(item) {
            let calitem = this.orgs.find(calitem => calitem.id === item);
            this.upParamsOrgacronymname(calitem.orgAcronymName)
        }
    }
}
</script>

<style>

</style>