import axios from 'axios'

export const namespaced = true

export const state = {
    sports: [],
    orgsUp: [],
    indivteams: [ 'Individual', 'Team', 'Individual/Team' ],
    seasons: [ 'Summer', 'Winter' ],
    olyplys: [ 'Olympic', 'Paralympic', 'Other'],
    sportparams: {
        id: null,
        sptName: '',
        sptGroup: '',
        orgUp: '',
        orgAcronymName: '',
        acronym: '',
        sptLkAgenda: '',
        indivTeam: '',
        olyply: '',
        season: '',
        insertedBy: '',
        updatedBy: '',
        createdAt: '',
        updatedAt: ''
    }
}

export const getters = {
    getSports: state => state.sports
}

export const mutations = {
    setSports: (state, sports) => (state.sports = sports),
    setSportbyid: (state, sport) => (state.sportparams = sport),
    setOrgsUp: (state, arrorgs) => (state.orgsUp = arrorgs),

    upParamsSptname: (state, upparamssptname) => (state.sportparams.sptName = upparamssptname),
    upParamsSptgroup: (state, upparamssptgroup) => (state.sportparams.sptGroup = upparamssptgroup),
    upParamsSptorgup: (state, upparamsorgup) => (state.sportparams.orgUp = upparamsorgup),
    upParamsSptorgacronymname: (state, upparamsorgacronymname) => (state.sportparams.orgAcronymName = upparamsorgacronymname),
    upParamsSptorgacronym: (state, upparamsorgacronym) => (state.sportparams.acronym = upparamsorgacronym),
    upParamsSptlkagenda: (state, upparamslkagenda) => (state.sportparams.sptLkAgenda = upparamslkagenda),
    upParamsSptindivteam: (state, upparamsindivteam) => (state.sportparams.indivTeam = upparamsindivteam),
    upParamsSptolyply: (state, upparamsolyply) => (state.sportparams.olyply = upparamsolyply),
    upParamsSptseason: (state, upparamsseason) => (state.sportparams.season = upparamsseason),
    upParamsInsertedby: (state, upparamsinsertedby) => (state.sportparams.insertedBy = upparamsinsertedby),
    upParamsUpdatedby: (state, upparamsupdatedby) => (state.sportparams.updatedBy = upparamsupdatedby),
    upParamsUpdatedat: (state, upparamsupdatedat) => (state.sportparams.updatedAt = upparamsupdatedat),

    newSport: (state, arrnewsport) => state.sports.push(arrnewsport),
    delSport: (state, id) => (state.sports = state.sports.filter(sport => sport.id !== id)),
    updSport: (state, upsport) => {
            const index = state.sports.findIndex(sport => sport.id === upsport.id)
            if (index !== -1) { state.sports.splice(index, 1, upsport) }
        },
}

export const actions = {
    async fetchSports( { commit } ) {
        let response = await axios.get('/sport')
        //console.log(JSON.stringify(response.data))
        //let arrsp = []
        let arrsports = response.data
        /*
        for (var i = 0; i < arrsptemp.length; i++) {
          arrsp.push(arrsptemp[i].sptName)
        }*/
        commit('setSports', arrsports)        
    },
    async fetchOrgs( { commit } ) {
        let response = await axios.get('/organisation/selorg')
        //console.log(JSON.stringify(response.data))
        //let arrsp = []
        let arrorgs = response.data
        /*
        for (var i = 0; i < arrsptemp.length; i++) {
          arrsp.push(arrsptemp[i].sptName)
        }*/
        commit('setOrgsUp', arrorgs)        
    },
    async fetchSportById( { commit }, sptid) {
        let response = await axios.get(`/sport/spt/${sptid}`)
        //console.log(response.config.url);
        //console.log(JSON.stringify(response.data))
        let arrsport = response.data
        commit('setSportbyid', arrsport)
    },
    
    async addSport( { commit }, parms) {
        //console.log(JSON.stringify(parms))
        //let response = await axios.post('/sport', parms )
        await axios.post('/sport', parms )
        //let arrnewsport = response.data
        //console.log(JSON.stringify(response.data))
        commit('newSport', parms)
    },
    async delSport( { commit }, id ) {
        await axios.delete(`/sport/${id}`)
        commit('delSport', id)
      },
    async updSport( { commit }, upparms) {
        let nowDate = new Date().toISOString()
        upparms.updatedAt = nowDate
        //console.log(JSON.stringify(upparms))
        await axios.put(`/sport/${upparms.id}`, upparms )
        commit('updSport', upparms)
    },
}