var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getEvents,"items-per-page":50,"item-key":"sptName"},scopedSlots:_vm._u([{key:"item.year",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"year-chip",attrs:{"color":_vm.getColor(item.year),"dark":""}},[_vm._v(_vm._s(item.year))])]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"totperiod"},[_c('div',{staticClass:"iniperiod"},[_c('div',[_vm._v(_vm._s(item.dtBegin.slice(8,10))+" - "+_vm._s(_vm.getMonth(item.dtBegin.slice(5,7))))])]),_c('div',{staticClass:"endperiod"},[_c('div',[_vm._v(_vm._s(item.dtEnd.slice(8,10))+" - "+_vm._s(_vm.getMonth(item.dtEnd.slice(5,7))))])])])]}},{key:"item.calName",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.calName))]),_c('br'),_vm._v(_vm._s(item.title.substr(0, 40) + '...')+" ")]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"city-link",attrs:{"href":'https://www.google.de/maps/place/'+item.city,"target":"_blank"}},[_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(item.city)+" ")],1)]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"country-flag",attrs:{"src":require('@/assets/flags/'+item.countryFlag.toLowerCase()+'.png'),"aspect-ratio":"0.7","contain":"","max-height":"70","title":item.countryName}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.delEvent(item.id)}}},[_vm._v("mdi-delete")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }