<template>
   <v-text-field
        label="Link to Organisation Website"
        v-model="isLkWebsite"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'organisation', [ 'orgparams' ] ),
        isLkWebsite: {
            get() { return this.orgparams.lkWebsite },
            set(item) { this.upParamsLkwebsite(item) }
        }
    },
    methods: {
        ...mapMutations( 'organisation', [ 'upParamsLkwebsite' ] )
    }
}
</script>

<style>

</style>