<template>
    <v-textarea 
        label="Description"
        v-model="isOrgDesc"
        filled
        hide-details
        rows="3"
    >
    </v-textarea>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'organisation', [ 'orgparams' ] ),
        isOrgDesc: {
            get() { return this.orgparams.orgDesc },
            set(item) { this.upParamsOrgdesc(item) }
        }
    },
    methods: {
        ...mapMutations( 'organisation', [ 'upParamsOrgdesc' ] )
    }
}
</script>

<style>

</style>