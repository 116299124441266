<template>
  <v-data-table
        :headers="headers"
        :items="getSports"
        :items-per-page="50"
        item-key="sptName"
    >
        <template v-slot:[`item.agenda`]="{ item }">                
            <a :href="item.sptLkAgenda" target="_blank">
                <v-icon right small>mdi-link-variant</v-icon>
                Agend by {{ item.acronym }} 
            </a>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <SportUpdate :sptid="item.id"/>
            <v-icon @click="delSport(item.id)">mdi-delete</v-icon>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import SportUpdate from '@/components/sport/Sportupdate'

export default {
    name: 'Sportdatatable',
    components: {
        SportUpdate
    },
    data () {
        return {
            drawerLeft: false,
            headers: [
                { text: 'Sport', align: 'left', sortable: false, value: 'sptName' },
                { text: 'Sport Group', align: 'left', sortable: false, value: 'sptGroup' },
                { text: 'Organisation Up', align: 'left', sortable: false, value: 'orgAcronymName' },
                { text: 'Sport Agenda Link', align: 'left', sortable: false, value: 'agenda' },
                { text: 'Individual/Team', align: 'center', sortable: false, value: 'indivTeam' },
                { text: 'Olympic/Paralympic', align: 'center', sortable: false, value: 'olyply' },
                { text: 'Season', align: 'center', sortable: false, value: 'season' },
                { text: 'Actions', align: 'center', value: 'actions', sortable: false },
            ],
            expanded: [],
            singleExpand: true,
        }
    },
    mounted() {
        this.fetchSports()
    },
    computed: {
        ...mapGetters( 'sport', [ 'getSports' ] )
    },
    methods: {
        ...mapActions( 'sport', [ 'fetchSports', 'delSport' ]),
    }
}
</script>

<style>

</style>