<template>
    <v-list class="pa-0" dense>
        <v-list-item-group multiple>
            <v-list-item-content class="pt-0">
                <v-list-item-title>Olympic/Paralympic:</v-list-item-title>
            </v-list-item-content>
            <v-list-item class="pa-0" @click="toggle">
                <v-list-item-action>
                    <v-icon>{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>All Oly/Ply Sports</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item class="pa-0" v-for="item in olyplys" :key="item">
                <v-list-item-action>
                    <v-checkbox v-model="isOlyply" :value="item"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'olyplys', 'searchparams' ] ),
        isOlyply: {
            get() { return this.searchparams.olyply },
            set(item) { this.upParamsOp(item) }
        },
        selAllOlyply () {
            return this.searchparams.olyply.length === this.olyplys.length
        },
        selSomeOlyply () {
            return this.searchparams.olyply.length > 0 && !this.selAllOlyply
        },
        icon () {
            if (this.selAllOlyply) return 'mdi-close-box'
            if (this.selSomeOlyply) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsOp' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllOlyply) {
                this.searchparams.olyply = []
            } else {
                this.searchparams.olyply = this.olyplys.slice()
            }
            })
        },
    }
}
</script>

<style>

</style>