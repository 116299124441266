<template>
    <v-list class="form-list-menu-left py-0" dense>
        <v-list-group no-action append-icon="mdi-chevron-down">
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title>Years</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item  class="px-4" @click="toggle">
                <v-list-item-action class="my-0">
                    <v-icon>{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>All Years</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="item in years" :key="item" class="px-4">
                <v-list-item-action class="my-0">
                    <v-checkbox v-model="isYears" :value="item"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
    </v-list>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'years', 'searchparams' ] ),
        isYears: {
            get() { return this.searchparams.year },
            set(item) { this.upParamsYr(item) }
        },
        selAllYears () {
            return this.searchparams.year.length === this.years.length
        },
        selSomeYears () {
            return this.searchparams.year.length > 0 && !this.selAllYears
        },
        icon () {
            if (this.selAllYears) return 'mdi-close-box'
            if (this.selSomeYears) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsYr' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllYears) {
                this.searchparams.year = []
            } else {
                this.searchparams.year = this.years.slice()
            }
            })
        },
    },
}
</script>

<style>

</style>