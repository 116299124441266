import axios from 'axios'

export const namespaced = true

export const state = {
    orgs: [],
    countries: [],
    orgsUp: [],
    orgTypes: [ 
        { orgTypeAbr: 'SO', orgType: 'Sport Organisation' },
        { orgTypeAbr: 'IF', orgType: 'International Federation' },
        { orgTypeAbr: 'NF', orgType: 'National Federation' },
        { orgTypeAbr: 'CL', orgType: 'Club' },
    ],
    orgparams: {
        id: null,
        acronym: '',
        orgName: '',
        orgType: '',
        orgDesc: '',
        orgUp: '',
        email: '',
        phone: '',
        adrStreetComp: '',
        adrPostalCode: '',
        adrCity: '',
        adrCountry: '',
        lkWebsite: '',
        lkAgenda: '',
        lkLinkedin: '',
        lkFacebook: '',
        lkYoutube: '',
        lkTwitter: '',
        lkInstagran: '',
        insertedBy: '',
        updatedBy: '',
        createdAt: '',
        updatedAt: ''
    }
}

export const getters = {
    getOrgs: state => state.orgs
}

export const mutations = {
    setOrgs: (state, orgs) => (state.orgs = orgs),
    setOrgbyid: (state, org) => (state.orgparams = org),
    setCountries: (state, countries) => (state.countries = countries),
    setOrgsUp: (state, arrorgs) => (state.orgsUp = arrorgs),

    upParamsAcronym: (state, upparamsacronym) => (state.orgparams.acronym = upparamsacronym),
    upParamsOrgname: (state, upparamsorgname) => (state.orgparams.orgName = upparamsorgname),
    upParamsOrgtype: (state, upparamsorgtype) => (state.orgparams.orgType = upparamsorgtype),
    upParamsOrgdesc: (state, upparamsorgdesc) => (state.orgparams.orgDesc = upparamsorgdesc),
    upParamsOrgup: (state, upparamsorgup) => (state.orgparams.orgUp = upparamsorgup),
    upParamsEmail: (state, upparamsemail) => (state.orgparams.email = upparamsemail),
    upParamsPhone: (state, upparamsphone) => (state.orgparams.phone = upparamsphone),
    upParamsAdrstreetcomp: (state, upparamsadrstreetcomp) => (state.orgparams.adrStreetComp = upparamsadrstreetcomp),
    upParamsAdrpostalcode: (state, upparamsadrpostalcode) => (state.orgparams.adrPostalCode = upparamsadrpostalcode),
    upParamsAdrcity: (state, upparamsadrcity) => (state.orgparams.adrCity = upparamsadrcity),
    upParamsAdrcountry: (state, upparamsadrcountry) => (state.orgparams.adrCountry = upparamsadrcountry),
    upParamsLkwebsite: (state, upparamslkwebsite) => (state.orgparams.lkWebsite = upparamslkwebsite),
    upParamsLkagenda: (state, upparamslkagenda) => (state.orgparams.lkAgenda = upparamslkagenda),
    upParamsLklinkedin: (state, upparamslklinkedin) => (state.orgparams.lkLinkedin = upparamslklinkedin),
    upParamsLkfacebook: (state, upparamslkfacebook) => (state.orgparams.lkFacebook = upparamslkfacebook),
    upParamsLkyoutube: (state, upparamslkyoutube) => (state.orgparams.lkYoutube = upparamslkyoutube),
    upParamsLktwitter: (state, upparamslktwitter) => (state.orgparams.lkTwitter = upparamslktwitter),
    upParamsLkinstagran: (state, upparamslkinstagran) => (state.orgparams.lkInstagran = upparamslkinstagran),
    upParamsInsertedby: (state, upparamsinsertedby) => (state.orgparams.insertedBy = upparamsinsertedby),
    upParamsUpdatedby: (state, upparamsupdatedby) => (state.orgparams.updatedBy = upparamsupdatedby),
    upParamsUpdatedat: (state, upparamsupdatedat) => (state.orgparams.updatedAt = upparamsupdatedat),

    newOrganisation: (state, arrneworg) => state.orgs.push(arrneworg),
    delOrganisation: (state, id) => (state.orgs = state.orgs.filter(org => org.id !== id)),
    updOrganisation: (state, uporg) => {
        const index = state.orgs.findIndex(org => org.id === uporg.id)
        if (index !== -1) { state.orgs.splice(index, 1, uporg) }
    },
}

export const actions = {
    async fetchOrgsByUser( { commit }, usrId) {
        let response = await axios.get(`/organisation/usr/${usrId}`)
        //console.log(response.config.url);
        //console.log(JSON.stringify(response.data))
        let arrorgs = response.data
        commit('setOrgs', arrorgs)
    },
    async fetchOrgById( { commit }, orgid) {
        let response = await axios.get(`/organisation/org/${orgid}`)
        //console.log(response.config.url);
        //console.log(JSON.stringify(response.data))
        let arrorg = response.data
        commit('setOrgbyid', arrorg)
    },
    async fetchCountries( { commit } ) {
        let response = await axios.get('/organisation/countries')
        //console.log(JSON.stringify(response.data))
        //console.log('teste')
        //let arrsp = []
        let arrcountries = response.data
        /*
        for (var i = 0; i < arrsptemp.length; i++) {
          arrsp.push(arrsptemp[i].sptName)
        }*/
        commit('setCountries', arrcountries)        
    },
    async fetchOrgs( { commit } ) {
        let response = await axios.get('/organisation/selorg')
        //console.log(JSON.stringify(response.data))
        //let arrsp = []
        let arrorgs = response.data
        /*
        for (var i = 0; i < arrsptemp.length; i++) {
          arrsp.push(arrsptemp[i].sptName)
        }*/
        commit('setOrgsUp', arrorgs)        
    },
    async addOrganisation( { commit }, parms) {
        //console.log(JSON.stringify(parms))
        let response = await axios.post('/organisation', parms )
        let arrneworg = response.data
        //console.log(JSON.stringify(response.data))
        commit('newOrganisation', arrneworg)
        //commit()//fetchOrgsByUser(parms.insertedBy))
    },
    async delOrganisation( { commit }, id ) {
        await axios.delete(`/organisation/${id}`)
        commit('delOrganisation', id)
      },
    async updOrganisation( { commit }, upparms) {
        let nowDate = new Date().toISOString()
        upparms.updatedAt = nowDate
        //console.log(JSON.stringify(upparms))
        await axios.put(`/organisation/${upparms.id}`, upparms )
        commit('updOrganisation', upparms)
    },
}