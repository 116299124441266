<template>
  <v-data-table
        :headers="headers"
        :items="getOrgs"
        :items-per-page="50"
        item-key="acronym"
    >
        <template v-slot:[`item.actions`]="{ item }">
            <OrgUpdate :orgid="item.id"/>
            <v-icon @click="delOrganisation(item.id)">mdi-delete</v-icon>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import OrgUpdate from '@/components/organisation/Orgupdate'

export default {
    name: 'Orgdatatable',
    components: {
        OrgUpdate
    },
    data () {
        return {
            drawerLeft: false,
            headers: [
                { text: 'Acronym', align: 'left', sortable: false, value: 'acronym' },
                { text: 'Name', align: 'left', sortable: false, value: 'orgName' },
                { text: 'Type', align: 'left', sortable: false, value: 'orgType' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            expanded: [],
            singleExpand: true,
        }
    },
    mounted() {
        this.fetchOrgsByUser(this.$auth.user.sub.slice(6))
    },
    computed: {
        ...mapGetters( 'organisation', [ 'getOrgs' ] )
    },
    methods: {
        ...mapActions( 'organisation', [ 'fetchOrgsByUser', 'delOrganisation' ]),
    }
}
</script>

<style>

</style>