import axios from 'axios'

export const namespaced = true

export const state = {
    calendars: [],
    orgs: [],
    caltypes: [ 'Default', 'Tournament', 'Personal', 'Organisation' ],
    orgroles: [ 'Admin', 'Edit', 'View' ],
    calparams: {
        id: null,
        calName: '',
        calDesc: '',
        calType: '',
        color: '',
        sharedFrom: null,
        sharedType: '',
        calUsrId: null,
        usrId: '',
        usrRole: '',
        calOrgId: null,
        orgId: '',
        orgAcronymName: '',
        orgRole: '',
        insertedBy: '',
        updatedBy: '',
        createdAt: '',
        updatedAt: ''
    }
}

export const getters = {
    getCalendars: state => state.calendars
}

export const mutations = {
    setCalendars: (state, calendars) => (state.calendars = calendars),
    setCalbyid: (state, calendar) => (state.calparams = calendar),
    setOrgs: (state, arrorgs) => (state.orgs = arrorgs),

    upParamsCalname: (state, upparamscalname) => (state.calparams.calName = upparamscalname),
    upParamsCaldesc: (state, upparamscaldesc) => (state.calparams.calDesc = upparamscaldesc),
    upParamsCaltype: (state, upparamscaltype) => (state.calparams.calType = upparamscaltype),
    upParamsColor: (state, upparamscolor) => (state.calparams.color = upparamscolor),
    upParamsSharedfrom: (state, upparamssharedfrom) => (state.calparams.sharedFrom = upparamssharedfrom),
    upParamsSharedtype: (state, upparamssharedtype) => (state.calparams.sharedType = upparamssharedtype),
    upParamsUsrid: (state, upparamsusrid) => (state.calparams.usrId = upparamsusrid),
    upParamsUsrrole: (state, upparamsusrrole) => (state.calparams.usrRole = upparamsusrrole),
    upParamsOrgid: (state, upparamsorgid) => (state.calparams.orgId = upparamsorgid),
    upParamsOrgacronymname: (state, upparamsorgacronymname) => (state.calparams.orgAcronymName = upparamsorgacronymname),
    upParamsOrgrole: (state, upparamsorgrole) => (state.calparams.orgRole = upparamsorgrole),
    upParamsInsertedby: (state, upparamsinsertedby) => (state.calparams.insertedBy = upparamsinsertedby),
    upParamsUpdatedby: (state, upparamsupdatedby) => (state.calparams.updatedBy = upparamsupdatedby),
    upParamsUpdatedat: (state, upparamsupdatedat) => (state.calparams.updatedAt = upparamsupdatedat),

    newCalendar: (state, arrnewcal) => state.calendars.push(arrnewcal),
    delCalendar: (state, id) => (state.calendars = state.calendars.filter(calendar => calendar.id !== id)),
    updCalendar: (state, upcal) => {
            const index = state.calendars.findIndex(cal => cal.id === upcal.id)
            if (index !== -1) { state.calendars.splice(index, 1, upcal) }
        },
}

export const actions = {
    async fetchCalendars( { commit } ) {
        let response = await axios.get('/sport')
        //console.log(JSON.stringify(response.data))
        //let arrsp = []
        let arrsports = response.data
        /*
        for (var i = 0; i < arrsptemp.length; i++) {
          arrsp.push(arrsptemp[i].sptName)
        }*/
        commit('setSports', arrsports)        
    },
    async fetchCalByUser( { commit }, usrId) {
        let response = await axios.get(`/calendar/usr/${usrId}`)
        //console.log(response.config.url);
        //console.log(JSON.stringify(response.data))
        let arrcals = response.data
        commit('setCalendars', arrcals)
    },
    async fetchOrgs( { commit } ) {
        let response = await axios.get('/organisation/selorg')
        //console.log(JSON.stringify(response.data))
        //let arrsp = []
        let arrorgs = response.data
        /*
        for (var i = 0; i < arrsptemp.length; i++) {
          arrsp.push(arrsptemp[i].sptName)
        }*/
        commit('setOrgs', arrorgs)        
    },
    async fetchCalById( { commit }, calid) {
        let response = await axios.get(`/calendar/${calid}`)
        //console.log(response.config.url);
        //console.log(JSON.stringify(response.data))
        let arrcal = response.data
        commit('setCalbyid', arrcal)
    },
    async addCalendar( { commit }, parms) {
        //console.log(JSON.stringify(parms))
        //let response = await axios.post('/sport', parms )
        await axios.post('/calendar', parms )
        //let arrnewsport = response.data
        //console.log(JSON.stringify(response.data))
        commit('newCalendar', parms)
    },
    async delCalendar( { commit }, id ) {
        await axios.delete(`/calendar/${id}`)
        commit('delCalendar', id)
      },
    async updCalendar( { commit }, upparms) {
        let nowDate = new Date().toISOString()
        upparms.updatedAt = nowDate
        console.log(JSON.stringify(upparms))
        await axios.put(`/calendar/${upparms.id}`, upparms )
        commit('updCalendar', upparms)
    },
}