<template>
    <v-row class="mt-2 no-gutters">
        <v-col class="px-0" cols="12"  md="12">
            <v-list class="py-0" dense>
                <v-toolbar class="cal-toolbar" color="white" flat>
                    <v-toolbar-title v-text="smtitle"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <CalCreate />
                </v-toolbar>
                <v-list-item v-for="item in getCalendars" :key="item" class="px-2">
                    <v-list-item-action class="my-0">
                        <v-checkbox 
                            v-model="calSelected" 
                            :color="item.CALENDAR_COLOR" 
                            :value="item.CALENDAR_ID"
                            @change="updCalSel(item);updCalAppts(calsel)"
                        ></v-checkbox>
                    </v-list-item-action>
                        <v-list-item-content> 
                            <v-list-item-title v-text="item.CALENDAR_NAME"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <CalUpdate :calid="item.CALENDAR_ID"/>
                        </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex' 
import CalCreate from '@/components/calendar/Calendarcreate'
import CalUpdate from '@/components/calendar/Calendarupdate'

export default {
    name: 'CalendarCheckbox',
    components: {
        CalCreate,
        CalUpdate
    },
    data() {
        return {
            smtitle: 'Calendars',
        }
    },
    mounted() {
        this.userid = this.$auth.user.sub.slice(6)
        this.fetchCalendars(this.userid)
        const cals = { calendarid: this.calsel }
        this.fetchAppointments(cals)
    },
    
    computed: {
        ...mapGetters([ 'getCalendars', 'getAppointments' ]),
        ...mapState([ 'calsel' ]),
        calSelected: {
            get() { return this.calsel },
            set(item) { this.updCalSelected(item) }
        },
    },

    methods: {
        ...mapActions([ 'fetchCalendars', 'updCalendar', 'fetchAppointments' ]),
        ...mapMutations([ 'updCalSelected' ]),
        updCalSel(calsel) {
            if (calsel.UID_SHARED == 'true') {
                calsel.UID_SHARED = 'false'
            } else {
                calsel.UID_SHARED = 'true'
            }
            const params = {
                CALENDAR_ID: this.calsel.CALENDAR_ID,
                CALENDAR_NAME: this.calsel.CALENDAR_NAME,
                CALENDAR_DESC: this.calsel.CALENDAR_DESC,
                CALENDAR_COLOR: this.calsel.CALENDAR_COLOR,
                UID_OWNER: this.$auth.user.sub.slice(6),
                UID_SHARED: this.calsel.UID_SHARED
            }
            this.updCalendar(calsel)
        },
        updCalAppts(arrcalsel) {
            const calssel = {calendarid: arrcalsel}
            this.fetchAppointments(calssel)  
        },
    }
}
</script>

<style>

.cal-toolbar > .v-toolbar__content {
    padding: 0 8px !important;
}

</style>