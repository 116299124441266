<template>
    <v-select
        class="bd-rad"
        v-model="isCoverages"
        :items="coverages"
        placeholder="Coverages"
        append-icon="mdi-chevron-down"
        outlined
        dense
        hide-details
        :menu-props="{ maxHeight: '400' }"
        multiple
    >
        <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
                <v-list-item-action>
                    <v-icon :color="searchparams.coverage.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Select All
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
                {{ item }}
            </span>
            <span
                v-if="index === 1"
                class="grey--text text-caption pl-1"
            >
                (+{{ searchparams.coverage.length - 1 }} others)
            </span>
        </template>
    </v-select>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'coverages', 'searchparams' ] ),
        isCoverages: {
            get() { return this.searchparams.coverage },
            set(item) { this.upParamsCo(item) }
        },
        selAllCoverages () {
            return this.searchparams.coverage.length === this.coverages.length
        },
        selSomeCoverages () {
            return this.searchparams.coverage.length > 0 && !this.selAllCoverages
        },
        icon () {
            if (this.selAllCoverages) return 'mdi-close-box'
            if (this.selSomeCoverages) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    mounted() {
        this.fetchCoverages()
    },
    methods: {
        ...mapActions( 'searchevent', [ 'fetchCoverages' ]),
        ...mapMutations( 'searchevent', [ 'upParamsCo' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllCoverages) {
                this.searchparams.coverage = []
            } else {
                this.searchparams.coverage = this.coverages.slice()
            }
            })
        },
    }
}
</script>

<style>

    .bd-rad {
        border-radius: 50px;
        font-size: 14px;
    }

</style>