<template>
    <v-col class="pr-0 py-0" cols="12" md="2">
        <v-row class="mt-2 no-gutters">
            <v-col class="px-0" cols="12"  md="12">
                <v-date-picker
                    v-model="date"
                    full-width
                    no-title
                    color="primary"
                ></v-date-picker>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <CalList />
    </v-col>
</template>

<script>
import CalList from '@/components/viewcalendar/Calendarcheckbox'

export default {
    name: 'CalendarLeftMenu',
    components: {
        CalList,
    },
}
</script>

<style>

</style>