<template>
    <v-autocomplete
        v-model="isOrgOwner"
        :items="orgs"
        item-value="id"
        item-text="acronym"
        dense
        filled
        hide-details
        label="Event Owner"
        append-icon="mdi-chevron-down"
        @change="onChange"
    ></v-autocomplete>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    mounted() {
        this.fetchOrgs()
    },
    computed: {
        ...mapState( 'event', [ 'eventparams', 'orgs' ] ),
        isOrgOwner: {
            get() { return this.eventparams.orgOwner },
            set(item) { this.upParamsOrgOwner(item) }
        }
    },
    methods: {
        ...mapActions( 'event', [ 'fetchOrgs'] ),
        ...mapMutations( 'event', [ 'upParamsOrgOwner', 'upParamsAcrOwner' ] ),
        onChange(item) {
            let orgitem = this.orgs.find(orgitem => orgitem.id === item);
            this.upParamsAcrOwner(orgitem.acronym)
        }
    }
}
</script>

<style>

</style>