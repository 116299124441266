<template>
   <v-text-field
        label="Link to Paper View Streaming"
        v-model="isLkStreamPp"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'event', [ 'eventparams' ] ),
        isLkStreamPp: {
            get() { return this.eventparams.lkStreamPp },
            set(item) { this.upParamsLkStreamPp(item) }
        }
    },
    methods: {
        ...mapMutations( 'event', [ 'upParamsLkStreamPp' ] )
    }
}
</script>

<style>

</style>