<template>
   <v-text-field
        label="Personal Website"
        v-model="isLkPersonalWebsite"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'user', [ 'userparams' ] ),
        isLkPersonalWebsite: {
            get() { return this.userparams.user_metadata.lkPersonalWebsite },
            set(item) { this.upParamsLkPersonalWebsite(item) }
        }
    },
    methods: {
        ...mapMutations( 'user', [ 'upParamsLkPersonalWebsite' ] )
    }
}
</script>

<style>

</style>