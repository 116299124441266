import axios from 'axios'

export const namespaced = true

export const state = {
    events: [],
    countries: [],
    cities: [],
    sports: [],
    orgs: [],
    cals: [],
    eventtypes: ['Multi-Sport Competition', 'One-Sport Competition', 'Conference'],
    coverages: ['World','Europe','South America'],
    genres: ['Men/Women','Women','Men'],
    tickets: ['Buy','Free','Closed','Not Defined'],
    eventparams: {
        id: null,
        aptId: null,
        calId: null,
        calAptId: null,
        eveHstId: null,
        eveSpoId: null,
        year: '',
        dtBegin: '',
        dtEnd: '',
        title: '',
        calName: '',
        aptDesc: '',
        aptLocal: '',
        color: '',
        sptId: null,
        sptName: '',
        city: '',
        ctrId: null,
        countryName: '',
        countryFlag: '',
        ticket: '',
        lkTicket: '',
        lkStreamOs: '',
        lkStreamPp: '',
        lkStreamFb: '',
        lkStreamYt: '',
        lkStreamTw: '',
        eventType: '',
        coverage: '',
        genre: '',
        orgOwner: '',
        acrOwner: '',
        orgExec: '',
        acrExec: '',
        uidOwner: '',
        insertedBy: '',
        updatedBy: '',
        createdAt: '',
        updatedAt: ''
    }
}

export const getters = {
    getEvents: state => state.events
}

export const mutations = {
    setEvents: (state, events) => (state.events = events),
    setCountries: (state, countries) => (state.countries = countries),
    setCities: (state, cities) => (state.cities = cities),
    setSports: (state, sports) => (state.sports = sports),
    setOrgs: (state, orgs) => (state.orgs = orgs),
    setCals: (state, cals) => (state.cals = cals),

    upParamsTitle: (state, upparamstitle) => (state.eventparams.title = upparamstitle),
    upParamsTournName: (state, upparamstournname) => (state.eventparams.calName = upparamstournname),
    upParamsAptDesc: (state, upparamsaptdesc) => (state.eventparams.aptDesc = upparamsaptdesc),
    upParamsDtBeginYear: (state, upparamsdtbeginyear) => (state.eventparams.year = upparamsdtbeginyear),
    upParamsDtBegin: (state, upparamsdtbegin) => (state.eventparams.dtBegin = upparamsdtbegin),
    upParamsDtEnd: (state, upparamsdtend) => (state.eventparams.dtEnd = upparamsdtend),
    upParamsAptLocal: (state, upparamsaptlocal) => (state.eventparams.aptLocal = upparamsaptlocal),
    upParamsCity: (state, upparamscity) => (state.eventparams.city = upparamscity),
    upParamsCountry: (state, upparamsctrid) => (state.eventparams.ctrId = upparamsctrid),
    upParamsCountryName: (state, upparamsctridname) => (state.eventparams.countryName = upparamsctridname),
    upParamsCountryFlag: (state, upparamsctridflag) => (state.eventparams.countryFlag = upparamsctridflag),
    upParamsSport: (state, upparamssptid) => (state.eventparams.sptId = upparamssptid),
    upParamsSportName: (state, upparamssptname) => (state.eventparams.sptName = upparamssptname),
    upParamsOrgOwner: (state, upparamsorgowner) => (state.eventparams.orgOwner = upparamsorgowner),
    upParamsAcrOwner: (state, upparamsacrowner) => (state.eventparams.acrOwner = upparamsacrowner),
    upParamsOrgExec: (state, upparamsorgexec) => (state.eventparams.orgExec = upparamsorgexec),
    upParamsAcrExec: (state, upparamsacrexec) => (state.eventparams.acrExec = upparamsacrexec),
    upParamsEventType: (state, upparamseventtype) => (state.eventparams.eventType = upparamseventtype),
    upParamsCoverage: (state, upparamscoverage) => (state.eventparams.coverage = upparamscoverage),
    upParamsGenre: (state, upparamsgenre) => (state.eventparams.genre = upparamsgenre),
    upParamsTicket: (state, upparamsticket) => (state.eventparams.ticket = upparamsticket),
    upParamsLkTicket: (state, upparamslkticket) => (state.eventparams.lkTicket = upparamslkticket),
    upParamsLkStreamOs: (state, upparamslkstreamos) => (state.eventparams.lkStreamOs = upparamslkstreamos),
    upParamsLkStreamPp: (state, upparamslkstreampp) => (state.eventparams.lkStreamPp = upparamslkstreampp),
    upParamsLkStreamFb: (state, upparamslkstreamfb) => (state.eventparams.lkStreamFb = upparamslkstreamfb),
    upParamsLkStreamYt: (state, upparamslkstreamyt) => (state.eventparams.lkStreamYt = upparamslkstreamyt),
    upParamsLkStreamTw: (state, upparamslkstreamtw) => (state.eventparams.lkStreamTw = upparamslkstreamtw),
    upParamsColor: (state, upparamscolor) => (state.eventparams.color = upparamscolor),
    upParamsUidOwner: (state, upparamsuidowner) => (state.eventparams.uidOwner = upparamsuidowner),
    upParamsCalid: (state, upparamscalid) => (state.eventparams.calId = upparamscalid),
    upParamsCalname: (state, upparamscalname) => (state.eventparams.calName = upparamscalname),
    upParamsInsertedby: (state, upparamsinsertedby) => (state.eventparams.insertedBy = upparamsinsertedby),
    upParamsUpdatedby: (state, upparamsupdatedby) => (state.eventparams.updatedBy = upparamsupdatedby),
    upParamsUpdatedat: (state, upparamsupdatedat) => (state.eventparams.updatedAt = upparamsupdatedat),

    newEvent: (state, arrnewevent) => state.events.push(arrnewevent),
    sortEvent: (state) => {
        state.events.sort((a,b) => {
            let compare = 0;
            if (a.dtBegin > b.dtBegin) { compare = 1; }
            else if (b.dtBegin > a.dtBegin) { compare = -1; }
            return compare;
        })
    },
    delEvent: (state, id) => (state.events = state.events.filter(event => event.id !== id)),
}

export const actions = {
    async fetchEventsByUser( { commit }, usrId) {
        let response = await axios.get(`/event/usr/${usrId}`)
        //console.log(response.config.url);
        //console.log(JSON.stringify(response.data))
        let arrevents = response.data
        commit('setEvents', arrevents)
    },
    async fetchCountries( { commit } ) {
        let response = await axios.get('/event/hostcountries')
        //console.log(JSON.stringify(response.data))
        //console.log('teste')
        //let arrsp = []
        let arrcountries = response.data
        /*
        for (var i = 0; i < arrsptemp.length; i++) {
          arrsp.push(arrsptemp[i].sptName)
        }*/
        commit('setCountries', arrcountries)        
    },
    /*
    async fetchCities( { commit }, countryId ) {
        let response = await axios.get(`/event/hostcities/${countryId}`)
        //console.log(response.config.url);
        //console.log(JSON.stringify(response.data))
        let arrcities = response.data
        commit('setCities', arrcities)
    },
    */
    async fetchSports( { commit } ) {
        let response = await axios.get('/event/sports')
        //console.log(JSON.stringify(response.data))
        //let arrsp = []
        let arrsports = response.data
        /*
        for (var i = 0; i < arrsptemp.length; i++) {
          arrsp.push(arrsptemp[i].sptName)
        }*/
        commit('setSports', arrsports)        
    },
    async fetchOrgs( { commit } ) {
        let response = await axios.get('/event/orgs')
        //console.log(JSON.stringify(response.data))
        //let arrsp = []
        let arrorgs = response.data
        /*
        for (var i = 0; i < arrsptemp.length; i++) {
          arrsp.push(arrsptemp[i].sptName)
        }*/
        commit('setOrgs', arrorgs)        
    },
    async fetchCals( { commit }, usrId ) {
        let response = await axios.get(`/event/cals/${usrId}`)
        //console.log(JSON.stringify(response.data))
        //let arrsp = []
        let arrcals = response.data
        /*
        for (var i = 0; i < arrsptemp.length; i++) {
          arrsp.push(arrsptemp[i].sptName)
        }*/
        commit('setCals', arrcals)        
    },
    async addEvent( { commit }, parms) {
        //console.log(JSON.stringify(parms))
        //let response = await axios.post('/sport', parms )
        await axios.post('/event', parms )
        //let arrnewsport = response.data
        //console.log(JSON.stringify(response.data))
        commit('newEvent', parms)
        commit('sortEvent')
    },
    async delEvent( { commit }, id ) {
        await axios.delete(`/event/${id}`)
        commit('delEvent', id)
      },
    async updEvent( { commit }, upparms) {
        let nowDate = new Date().toISOString()
        upparms.updatedAt = nowDate
        //console.log(JSON.stringify(upparms))
        await axios.put(`/event/${upparms.id}`, upparms )
        commit('updEvent', upparms)
    },
}