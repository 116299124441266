<template>
  <v-container v-model="avatar" class="pa-0 d-flex justify-center align-center">
        <v-avatar size="180px" v-ripple v-if="!avatar" class="grey lighten-3">
            <v-img max-height="100" max-width="100" src="@/assets/user-create.png"></v-img>
        </v-avatar>
        <v-avatar size="180px" v-ripple v-else-if="avatar">
            <v-img :src="url"></v-img>
        </v-avatar>
        <v-file-input 
            v-model="isUserPhoto"
            filled
            prepend-inner-icon="mdi-camera"
            prepend-icon=""
            label="Insert User Photo"
            hide-details
            class="ml-6"
            @change="previewImage"
            @click:clear="Clear()"
        ></v-file-input>
        
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data() {
        return {
            url: null,
            //image: null,
            avatar: false,
        }
    },
    computed: {
        ...mapState( 'user', [ 'userparams' ] ),
        isUserPhoto: {
            get() { return this.userparams.user_metadata.photo.file },
            set(item) {this.upParamsPhoto(item)}
        }
    },
    methods: {
        ...mapMutations( 'user', [ 'upParamsPhoto' ] ),
        previewImage(item) {
            this.url= URL.createObjectURL(item)
            this.avatar = true
        },
        Clear() { 
            this.avatar = false
        }
    }
}
</script>

<style>

</style>