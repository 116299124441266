<template>
    <v-list class="form-list-menu-left py-0" dense>
        <v-list-group no-action append-icon="mdi-chevron-down">
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title>Live Streams</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item  class="px-4" @click="toggle">
                <v-list-item-action class="my-0">
                    <v-icon>{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>All Events</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="item in livestreams" :key="item" class="px-4">
                <v-list-item-action class="my-0">
                    <v-checkbox v-model="isLives" :value="item"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
    </v-list>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'livestreams', 'searchparams' ] ),
        isLives: {
            get() { return this.searchparams.livestream },
            set(value) { this.upParamsLs(value) }
        },
        selAllLives () {
            return this.searchparams.livestream.length === this.livestreams.length
        },
        selSomeLives () {
            return this.searchparams.livestream.length > 0 && !this.selAllLives
        },
        icon () {
            if (this.selAllLives) return 'mdi-close-box'
            if (this.selSomeLives) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsLs' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllLives) {
                this.searchparams.livestream = []
            } else {
                this.searchparams.livestream = this.livestreams.slice()
            }
            })
        },
    },
}
</script>

<style>

</style>