<template>
    <v-select
        v-model="isCalOrgRole"
        :items="orgroles"
        label="Organisation Role"
        append-icon="mdi-chevron-down"
        filled
        hide-details
    ></v-select>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'calendar', [ 'orgroles', 'calparams' ] ),
        isCalOrgRole: {
            get() { return this.calparams.orgRole },
            set(item) { this.upParamsOrgrole(item) }
        },
    },
    methods: {
        ...mapMutations( 'calendar', [ 'upParamsOrgrole' ] ),
    }
}
</script>

<style>

</style>