<template>
    <v-select
        class="bd-rad"
        v-model="isSports"
        :items="sports"
        placeholder="Sports"
        append-icon="mdi-chevron-down"
        outlined
        dense
        hide-details
        :menu-props="{ maxHeight: '400' }"
        multiple
    >
        <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
                <v-list-item-action>
                    <v-icon :color="searchparams.sport.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Select All
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
                {{ item }}
            </span>
            <span
                v-if="index === 1"
                class="grey--text text-caption pl-1"
            >
                (+{{ searchparams.sport.length - 1 }} others)
            </span>
        </template>
    </v-select>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState( 'searchevent', [ 'sports', 'searchparams' ] ),
        isSports: {
            get() { return this.searchparams.sport },
            set(item) { this.upParamsSp(item) }
        },
        selAllSports () {
            return this.searchparams.sport.length === this.sports.length
        },
        selSomeSports () {
            return this.searchparams.sport.length > 0 && !this.selAllSports
        },
        icon () {
            if (this.selAllSports) return 'mdi-close-box'
            if (this.selSomeSports) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    mounted() {
        this.fetchSports()
    },
    methods: {
        ...mapActions( 'searchevent', [ 'fetchSports' ]),
        ...mapMutations( 'searchevent', [ 'upParamsSp' ] ),
        toggle () {
            this.$nextTick(() => {
            if (this.selAllSports) {
                this.searchparams.sport = []
            } else {
                this.searchparams.sport = this.sports.slice()
            }
            })
        },
    },
}
</script>

<style>

    .bd-rad {
        border-radius: 50px;
        font-size: 14px;
    }

</style>