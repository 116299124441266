<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-2" @click="fetchOrgById(orgid)">mdi-pencil</v-icon>
        </template>
        <v-card>
            <v-form @submit.prevent="orgUpdSubmit">
                <v-card-title class="light-blue darken-3" dark>
                    <span class="headline">Create a New Organisation</span>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pa-8">
                    <v-row>
                        <v-col class="pr-8" cols="12" md="8"> 
                            <v-row>
                                <v-col cols="12" md="3"><Orgacronym /></v-col>
                                <v-col cols="12" md="9"><Orgname /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Orgtype /></v-col>
                                <v-col><Orgup /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Orgdesc /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Orgaemail/></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Orgaddress /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Orgcity /></v-col>
                                <v-col><Orgcountry /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Orgpostalcode /></v-col>
                                <v-col><Orgphone /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Orglkwebsite /></v-col>
                                <v-col><Orglkli /></v-col>
                                <!--<v-col><Orglkagenda /></v-col>-->
                            </v-row>
                            <v-row>
                                <v-col><Orglkfb /></v-col>
                                <v-col><Orglkyt /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><Orglktw /></v-col>
                                <v-col><Orglkin /></v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pl-8" cols="12" md="4">
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <p class="py-0 my-0">Owner</p>
                                    <h3>{{ $auth.user.name }}</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <p class="ma-0">Created at: {{orgparams.createdAt}}<br>
                                    Last Update at: {{orgparams.updatedAt}}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-card-actions class="px-0">
                                        <v-btn
                                            type="submit"
                                            color="light-blue darken-3 px-4"
                                            rounded
                                            dark
                                            @click="dialog = false"
                                        >
                                            Update
                                        </v-btn>
                                        <v-btn 
                                            type="submit"
                                            color="red darken-3 px-4"
                                            rounded
                                            dark
                                            @click="dialog = false; delOrganisation(orgparams.id)"
                                        >
                                            Delete
                                        </v-btn>
                                    </v-card-actions>    
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Orgacronym from './Orgacronym'
import Orgname from './Orgname'
import Orgtype from './Orgtype'
import Orgdesc from './Orgdesc'
import Orgup from './Orgup'
import Orgaemail from './Orgaemail'
import Orgphone from './Orgphone'
import Orgaddress from './Orgaddress'
import Orgpostalcode from './Orgpostalcode'
import Orgcountry from './Orgcountry'
import Orgcity from './Orgcity'
import Orglkwebsite from './Orglkwebsite'
import Orglkagenda from './Orglkagenda'
import Orglkli from './Orglkli'
import Orglkfb from './Orglkfb'
import Orglkyt from './Orglkyt'
import Orglktw from './Orglktw'
import Orglkin from './Orglkin'

export default {
    components: {
        Orgacronym,
        Orgname,
        Orgtype,
        Orgdesc,
        Orgup,
        Orgaemail,
        Orgphone,
        Orgaddress,
        Orgpostalcode,
        Orgcountry,
        Orgcity,
        Orglkwebsite,
        Orglkagenda,
        Orglkli,
        Orglkfb,
        Orglkyt,
        Orglktw,
        Orglkin,
    },
    props: [ 'orgid' ],
    data: () => ({
        dialog: false
    }),
    computed: {
        ...mapState( 'organisation', [ 'orgparams' ] ),
    },
    methods: {
        ...mapActions( 'organisation', [ 'fetchOrgById', 'updOrganisation', 'delOrganisation' ] ),
        orgUpdSubmit() {
            this.updOrganisation(this.orgparams)
                .then(this.fetchOrgsByUser(this.$auth.user.sub.slice(6)))
        },
    },
}
</script>

<style>
    .headline {
        color: white;
    }
    .collor-picker-new {
        max-width: none !important;
    }
</style>