<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" @click="fetchOneAppointment(apptid)" icon>
                <v-icon left>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-form @submit.prevent="apptUpSubmit">
                <v-card-title class="light-blue darken-3" dark>
                    <span class="headline">Update Appointment</span>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-container>
                <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    label="Title" 
                                    v-model="isApptTitle" 
                                    hide-details>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-menu v-model="fromDateMenu"
                                    :close-on-content-click="false"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                    >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            placeholder="Initial Date"
                                            readonly
                                            :value="isApptDtbg"
                                            v-on="on"
                                            outlined dense hide-details
                                            append-icon="mdi-calendar"
                                            clearable
                                            clear-icon="mdi-close"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="isApptDtbg"
                                        no-title
                                        @input="fromDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="isApptDtbgTime"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="isApptDtbgTime"
                                            label="Initial Time"
                                            append-icon="mdi-clock-time-four-outline"
                                            readonly outlined dense hide-details clearable
                                            clear-icon="mdi-close"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="menu2"
                                        v-model="isApptDtbgTime"
                                        full-width
                                        no-title
                                        @click:minute="$refs.menu2.save(isApptDtbgTime)"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu v-model="toDateMenu"
                                    :close-on-content-click="false"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            placeholder="End Date"
                                            readonly
                                            :value="isApptDtend"
                                            v-on="on"
                                            outlined dense hide-details
                                            append-icon="mdi-calendar"
                                            clearable
                                            clear-icon="mdi-close"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="isApptDtend"
                                        no-title
                                        @input="toDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu
                                    ref="menu4"
                                    v-model="menu4"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="isApptDtendTime"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="isApptDtendTime"
                                            label="End Time"
                                            append-icon="mdi-clock-time-four-outline"
                                            readonly outlined dense hide-details clearable
                                            clear-icon="mdi-close"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="menu4"
                                        v-model="isApptDtendTime"
                                        full-width
                                        no-title
                                        @click:minute="$refs.menu4.save(isApptDtendTime)"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    label="Local" 
                                    v-model="isApptLocal" 
                                    hide-details>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea 
                                    label="Description" 
                                    v-model="isApptDesc" 
                                    rows="3">
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <p class="py-0 my-0">Color</p>
                                <v-color-picker
                                    v-model="isApptColor"
                                    dot-size="20"
                                    hide-canvas
                                    hide-inputs
                                    hide-mode-switch
                                    hide-sliders
                                    show-swatches
                                    swatches-max-height="150"
                                ></v-color-picker>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select
                                    v-model="getCalendars.CALENDAR_ID"
                                    :items="getCalendars"
                                    item-value="CALENDAR_ID"
                                    item-text="CALENDAR_NAME"
                                    placeholder="Calendar"
                                    append-icon="mdi-chevron-down"
                                    outlined dense hide-details
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <p class="py-0 my-0">Owner</p>
                                <h3>{{ $auth.user.name }}</h3>
                            </v-col>
                        </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" color="blue darken-1" text @click="dialog = false">
                        Update
                    </v-btn>
                    <v-btn color="red" text @click="dialog = false; delAppt(apptparams.id)">
                        Delete
                    </v-btn>
                </v-card-actions>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'AppointmentUp',
    props: [ 'apptid' ],
    data: () => ({
        dialog: false,
        timeini: null,
        timeend: null,
        menu2: false,
        menu4: false,
        toDateMenu: false,
        fromDateMenu: false,
    }),
    computed: {
        ...mapState([ 'apptparams', 'calsel' ]),
        ...mapGetters([ 'getCalendars' ]),
        isApptDtbg: {
            get() { return this.apptparams.start },
            set(value) { this.updApptDtbg(value) }
        },
        isApptDtbgTime:  {
            get() { return this.apptparams.starttime },
            set(value) { this.updApptDtbgTime(value) }
        },
        isApptDtend: {
            get() { return this.apptparams.end },
            set(value) { this.updApptDtend(value) }
        },
        isApptDtendTime: {
            get() { return this.apptparams.endtime },
            set(value) { this.updApptDtendTime(value) }
        },
        isApptTitle: {
            get() { return this.apptparams.title },
            set(value) { this.updApptTitle(value) }
        },
        isApptLocal: {
            get() { return this.apptparams.local },
            set(value) { this.updApptLocal(value) }
        },
        isApptDesc: {
            get() { return this.apptparams.desc },
            set(value) { this.updApptDesc(value) }
        },
        isApptColor: {
            get() { return this.apptparams.color },
            set(value) { this.updApptColor(value) }
        },
        isApptCal: {
            get() { return this.apptparams.calid },
            set(value) { this.updApptCal(value) }
        },
    },
    methods: {
        ...mapActions([ 'updAppointment', 
                        'fetchOneAppointment',
                        'delAppointment',
                        'fetchAppointments'
                    ]),
        ...mapMutations([ 
            'updApptDtbg', 
            'updApptDtend',
            'updApptTitle',
            'updApptLocal',
            'updApptDesc',
            'updApptColor',
            'updApptCal', 
        ]),
        apptUpSubmit() {
            let dtb = this.apptparams.start
            let dte = this.apptparams.end
            
            let params = {
                APT_ID: this.apptparams.id,
                DT_INSERT: new Date().toISOString().substr(0, 10),
                DT_BEGIN: dtb.concat('T').concat(this.apptparams.starttime).concat(':00Z'),
                DT_END: dte.concat('T').concat(this.apptparams.endtime).concat(':00Z'),
                APT_TITLE: this.apptparams.title,
                APT_DESC: this.apptparams.desc,
                APT_LOCAL: this.apptparams.local,
                APT_COLOR: this.apptparams.color,
                APT_RECURRENCE: this.apptparams.recur,
                CALENDAR_ID: this.getCalendars.calid,
                UID_OWNER: this.$auth.user.sub.slice(6),
                UID_SHARED: this.apptparams.guests
            }
            console.log(JSON.stringify(params))
            console.log(JSON.stringify(dtb))
            console.log(JSON.stringify(dte))
            
            this.updAppointment(params).then(() => {
                const calssel = {calendarid: this.calsel}
                this.fetchAppointments(calssel)
                //console.log(JSON.stringify(calssel))
                })
              
        },
        
        updCalAppts(arrcalsel) {
            const calssel = {calendarid: arrcalsel}
            this.fetchAppointments(calssel)
            //console.log(JSON.stringify(calssel))
        },

        delAppt(apptid) {
            this.delAppointment(apptid).then(() => {
                const calssel = {calendarid: this.calsel}
                this.fetchAppointments(calssel)
                this.$emit('closeAppt')
            })
        }

    },
}
</script>

<style>
    .headline {
        color: white;
    }
</style>