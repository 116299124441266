<template>
    <v-container class="pa-0" fluid>
        <v-radio-group class="pa-0 ma-0" v-model="isSportOlyPly">
            <template v-slot:label>
                <div><strong>Olympic/Paralympic</strong></div>
            </template>
            <v-radio
                v-for="item in olyplys"
                :key="item"
                :label="item"
                :value="item"
            ></v-radio>
        </v-radio-group>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'sport', [ 'olyplys', 'sportparams' ] ),
        isSportOlyPly: {
            get() { return this.sportparams.olyply },
            set(item) { this.upParamsSptolyply(item) }
        }
    },
    methods: {
        ...mapMutations( 'sport', [ 'upParamsSptolyply' ] )
    }
}
</script>

<style>

</style>