<template>
    <v-autocomplete
        v-model="isAdrCountry"
        :items="countries"
        item-value="id"
        item-text="countryName"
        dense
        filled
        hide-details
        label="Country"
    ></v-autocomplete>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    mounted() {
        this.fetchCountries()
    },
    computed: {
        ...mapState( 'user', [ 'userparams', 'countries' ] ),
        isAdrCountry: {
            get() { return this.userparams.user_metadata.adrCountry },
            set(item) { this.upParamsAdrCountry(item) }
        }
    },
    methods: {
        ...mapActions( 'user', [ 'fetchCountries' ] ),
        ...mapMutations( 'user', [ 'upParamsAdrCountry' ] )
    }
}
</script>

<style>

</style>