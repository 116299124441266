<template>
    <v-menu v-model="toDateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                placeholder="End Date"
                readonly
                :value="isEnddate"
                v-on="on"
                outlined
                hide-details
                append-icon="mdi-calendar"
                clearable
                clear-icon="mdi-close"
                @click:clear="upParamsDtEnd('')"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="isEnddate"
            no-title
            @input="toDateMenu = false"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data: () => ({
        toDateMenu: false
    }),
    computed: {
        ...mapState( 'event', [ 'eventparams' ] ),
        isEnddate: {
            get() { return this.eventparams.dtEnd },
            set(findate) { this.upParamsDtEnd(findate) }
        }
    },
    mounted() {      
        this.iniEnddt()
    },
    methods: {
        ...mapMutations( 'event', [ 'upParamsDtEnd' ] ),
        iniEnddt() {
            this.isEnddate = ''
        }
    },
}
</script>

<style>

</style>