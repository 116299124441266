<template>
    <v-menu v-model="toDateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        >
        <template v-slot:activator="{ on }">
            <v-text-field class="fm-dt-fin bd-rad"
                placeholder="End Date"
                readonly
                :value="isFindate"
                v-on="on"
                outlined dense hide-details
                append-icon="mdi-calendar"
                clearable
                clear-icon="mdi-close"
                @click:clear="upParamsFd('')"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="isFindate"
            no-title
            @input="toDateMenu = false"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data: () => ({
        toDateMenu: false
    }),
    computed: {
        ...mapState( 'searchevent', [ 'searchparams' ] ),
        isFindate: {
            get() { return this.searchparams.findate },
            set(item) { this.upParamsFd(item) }
        }
    },
    mounted() {      
        this.iniFindt()
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsFd' ] ),
        iniFindt() {
            this.isFindate = ''
        }
    },
}
</script>

<style>

</style>