<template>
    <v-select
        v-model="isLives"
        :items="orgTypes"
        item-text="orgType"
        item-value="orgTypeAbr"
        label="Org Type"
        append-icon="mdi-chevron-down"
        filled
        hide-details
    ></v-select>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'organisation', [ 'orgTypes', 'orgparams' ] ),
        isLives: {
            get() { return this.orgparams.orgType },
            set(item) { this.upParamsOrgtype(item) }
        },
    },
    methods: {
        ...mapMutations( 'organisation', [ 'upParamsOrgtype' ] ),
    }
}
</script>

<style>

</style>