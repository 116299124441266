import axios from 'axios'

export const namespaced = true

export const state ={
    users: [],
    countries: [],
    orgsUp: [],
    genres: ['Men/Women','Women','Men'],
    roles: ['Master','Sport Manager','Event Manager','Athlete'],
    userparams: {
        email: '', // required *
        phone_number: '', // phone
        user_metadata: {
            middleName: '',
            photo: {},
            role: '',
            dateBirth: '',
            genre: '',
            adrStreetComp: '',
            adrPostalCode: '',
            adrCity: '',
            adrCountry: null,
            orgUp: null,
            orgReport: '',
            orgPosition: '',
            lkPersonalWebsite: '',
            lkLinkedin: '',
            lkFacebook: '',
            lkYoutube: '',
            lkTwitter: '',
            lkInstagran: '',
            insertedBy: '',
            updatedBy: '',
        },
        given_name: '', //firstName
        family_name: '', //lastName
        name: '', // new
        nickname: '',
        picture: '',
        user_id: null,
        connection: "Username-Password-Authentication", //required *
        password: '', //required *
        // username: "johndoe", // new
        created_at: '',
        updated_at: ''
    }
}

export const getters = {
    getUsers: state => state.users
}

export const mutations = {
    setUsers: (state, users) => (state.users = users),
    setCountries: (state, arrcountries) => (state.countries = arrcountries),
    setOrgsUp: (state, arrorgs) => (state.orgsUp = arrorgs),
    setPicture: (state, userpic) => (state.userparams.picture = userpic),

    upParamsEmail: (state, upemail) => (state.userparams.email = upemail),
    upParamsPhone: (state, upphone) => (state.userparams.phone_number = upphone),
    upParamsMiddleName: (state, upmiddlename) => (state.userparams.user_metadata.middleName = upmiddlename),
    //upParamsPhoto: (state, upphoto) => (state.userparams.photo = upphoto),
    upParamsPhoto: (state, upphoto) => {
        const data = new FormData()
            data.append("name", "file")
            data.append("file", upphoto)
        state.userparams.user_metadata.photo = data
    },
    upParamsRole: (state, uprole) => (state.userparams.user_metadata.role = uprole),
    /*
    upParamsDateBirth: (state, updatebirth) => {
        if (updatebirth.field === "day") {(state.userparams.user_metadata.dateBirth = state.userparams.user_metadata.dateBirth.slice(0,4) + "-" + state.userparams.user_metadata.dateBirth.slice(5,7) + "-" + updatebirth.val )}
        else if (updatebirth.field === "month") {(state.userparams.user_metadata.dateBirth = state.userparams.user_metadata.dateBirth.slice(0,4) + "-" + updatebirth.val + "-" + state.userparams.user_metadata.dateBirth.slice(8,10) )}
        else if (updatebirth.field === "year") {(state.userparams.user_metadata.dateBirth = updatebirth.val + "-" + state.userparams.user_metadata.dateBirth.slice(5,7) + "-" + state.userparams.user_metadata.dateBirth.slice(8,10) )}
    },
    */
    upParamsDateBirth: (state, updatebirth) => (state.userparams.user_metadata.dateBirth = updatebirth),
    upParamsGenre: (state, upgenre) => (state.userparams.user_metadata.genre = upgenre),
    upParamsAdrStreetComp: (state, upadrstreet) => (state.userparams.user_metadata.adrStreetComp = upadrstreet),
    upParamsAdrPostalCode: (state, upadrpostalcode) => (state.userparams.user_metadata.adrPostalCode = upadrpostalcode),
    upParamsAdrCity: (state, upadrcity) => (state.userparams.user_metadata.adrCity = upadrcity),
    upParamsAdrCountry: (state, upadrcountry) => (state.userparams.user_metadata.adrCountry = upadrcountry),
    upParamsOrgUp: (state, uporgup) => (state.userparams.user_metadata.orgUp = uporgup),
    upParamsOrgReport: (state, uporgreport) => (state.userparams.user_metadata.orgReport = uporgreport),
    upParamsOrgPosition: (state, uporgposition) => (state.userparams.user_metadata.orgPosition = uporgposition),
    upParamsLkPersonalWebsite: (state, uplkperswebsite) => (state.userparams.user_metadata.lkPersonalWebsite = uplkperswebsite),
    upParamsLkLinkedin: (state, uplklinkedin) => (state.userparams.user_metadata.lkLinkedin = uplklinkedin),
    upParamsLkFacebook: (state, uplkfacebook) => (state.userparams.user_metadata.lkFacebook = uplkfacebook),
    upParamsLkYoutube: (state, uplkyoutube) => (state.userparams.user_metadata.lkYoutube = uplkyoutube),
    upParamsLkTwitter: (state, uplktwitter) => (state.userparams.user_metadata.lkTwitter = uplktwitter),
    upParamsLkInstagran: (state, uplkinstagran) => (state.userparams.user_metadata.lkInstagran = uplkinstagran),
    upParamsInsertedby: (state, upparamsinsertedby) => (state.userparams.user_metadata.insertedBy = upparamsinsertedby),
    upParamsUpdatedby: (state, upparamsupdatedby) => (state.userparams.user_metadata.updatedBy = upparamsupdatedby),
    upParamsFirstName: (state, upfirstname) => (state.userparams.given_name = upfirstname),
    upParamsLastName: (state, uplastname) => (state.userparams.family_name = uplastname),
    upParamsName: (state, updatename) => {
        if (updatename.field === "first") {(state.userparams.name = updatename.val + " " + state.userparams.family_name)}
        else if (updatename.field === "last") {(state.userparams.name = state.userparams.given_name + " " + updatename.val)}
    },
    upParamsNickName: (state, upnickname) => (state.userparams.nickname = upnickname),
    upParamsPassword: (state, uppassword) => (state.userparams.password = uppassword),
    upParamsUpdatedat: (state, upparamsupdatedat) => (state.userparams.updated_at = upparamsupdatedat),

    newUser: (state, arrnewuser) => state.users.push(arrnewuser),
    delUser: (state, id) => (state.users = state.users.filter(user => user.user_id !== id)),
}

export const actions = {
    async addUser( { commit }, parms) {
        //console.log(JSON.stringify(parms))
        await axios.get('/user/crt', { params: parms } )
                    .catch(error => {
                        console.log(error.response.data.error)
                        console.log(error.response)
                    })
        commit('newUser', parms)
    },
    async addUserPhoto( { commit }, filedata) {
        //console.log(JSON.stringify(filedata))
        let response = await axios.post('/user/crt', filedata)
        let userpic = response.data
        //console.log(userpic)
        commit('setPicture', userpic)
    },
    async fetchUsers( { commit } ) {
        let response = await axios.get('/user/allusers')
        let arrusers = response.data
        console.log(JSON.stringify(response.data))
        commit('setUsers', arrusers)     
    },
    async fetchCountries( { commit } ) {
        let response = await axios.get('/organisation/countries')
        let arrcountries = response.data
        commit('setCountries', arrcountries)        
    },
    async fetchOrgs( { commit } ) {
        let response = await axios.get('/organisation/selorg')
        let arrorgs = response.data
        commit('setOrgsUp', arrorgs)        
    },
    async updUser( { commit }, upparms) {
        let nowDate = new Date().toISOString()
        upparms.updatedAt = nowDate
        await axios.put(`/user/upt/${upparms.id}`, upparms )
        commit('updSport', upparms)
    },
    async delUser( { commit }, id ) {
        await axios.get(`/user/del/${id}`)
        commit('delUser', id)
      },
    
}