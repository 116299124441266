<template>
    <v-btn
        elevation="0"
        text
        color="primary"
        small
        @click="clearField"
    >
        Reset Fields
    </v-btn>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    methods: {
        ...mapMutations( 'searchevent', [
            'upParamsTx',
            'upParamsYr',
            'upParamsId',
            'upParamsFd',
            'upParamsSp',
            'upParamsCo',
            'upParamsHc',
            'upParamsSo',
            'upParamsEt',
            'upParamsSs',
            'upParamsOp',
            'upParamsGe',
            'upParamsLs',
            'upParamsTk'
        ]),
        clearField() {
            this.upParamsTx('')
            this.upParamsYr([])
            this.upParamsId(new Date().toISOString().substr(0, 10))
            this.upParamsFd('')
            this.upParamsSp([])
            this.upParamsCo([])
            this.upParamsHc([])
            this.upParamsSo([])
            this.upParamsEt([])
            this.upParamsSs([])
            this.upParamsOp([])
            this.upParamsGe([])
            this.upParamsLs([])
            this.upParamsTk([])
        }
    }

}
</script>

<style>

</style>