<template>
    <v-toolbar flat>
        <v-toolbar-title>Sport Management</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <SportCreate />
    </v-toolbar>
</template>

<script>
import SportCreate from '@/components/sport/Sportcreate'

export default {
    name: 'Sporttoolbar',
    components: {
        SportCreate
    }
}
</script>

<style>

</style>