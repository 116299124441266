<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-2" @click="fetchSportById(sptid)">mdi-pencil</v-icon>
        </template>
        <v-card>
            <v-form @submit.prevent="sptUpdSubmit">
                <v-card-title class="light-blue darken-3" dark>
                    <span class="headline">Create a New Organisation</span>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pa-8">
                    <v-row>
                        <v-col class="pr-8" cols="12" md="8"> 
                            <v-row>
                                <v-col><SportName /></v-col>
                                <v-col><SportGroup /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><SportOrgUp /></v-col>
                                <v-col><SportLkAgenda /></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="3"><SportIndivTeam /></v-col>
                                <v-col cols="12" md="3"><SportOlyPly /></v-col>
                                <v-col cols="12" md="3"><SportSeason /></v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pl-8" cols="12" md="4">
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <p class="py-0 my-0">Owner</p>
                                    <h3>{{ $auth.user.name }}</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <p class="ma-0">Created at: {{sportparams.createdAt}}<br>
                                    Last Update at: {{sportparams.updatedAt}}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-card-actions class="px-0">
                                        <v-btn
                                            type="submit"
                                            color="light-blue darken-3 px-4"
                                            rounded
                                            dark
                                            @click="dialog = false"
                                        >
                                            Update
                                        </v-btn>
                                        <v-btn 
                                            type="submit"
                                            color="red darken-3 px-4"
                                            rounded
                                            dark
                                            @click="dialog = false; delSport(sportparams.id)"
                                        >
                                            Delete
                                        </v-btn>
                                    </v-card-actions>    
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import SportName from './Sportname'
import SportGroup from './Sportgroup'
import SportOrgUp from './Sportorgup'
import SportLkAgenda from './Sportlkagenda'
import SportIndivTeam from './Sportindivteam'
import SportOlyPly from './Sportolyply'
import SportSeason from './Sportseason'

export default {
    components: {
        SportName,
        SportGroup,
        SportOrgUp,
        SportLkAgenda,
        SportIndivTeam,
        SportOlyPly,
        SportSeason
    },
    props: [ 'sptid' ],
    data: () => ({
        dialog: false
    }),
    computed: {
        ...mapState( 'sport', [ 'sportparams' ] )
    },
    methods: {
        ...mapActions( 'sport', [ 'fetchSports', 'fetchSportById', 'updSport', 'delSport' ] ),
        sptUpdSubmit() {
            this.updSport(this.sportparams)
                .then(this.fetchSports())
        },
    },
}
</script>

<style>
    .headline {
        color: white;
    }
    .collor-picker-new {
        max-width: none !important;
    }
</style>