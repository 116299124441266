<template>
    <v-select
        v-model="isCalType"
        :items="caltypes"
        label="Calendar Type"
        append-icon="mdi-chevron-down"
        filled
        hide-details
    ></v-select>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'calendar', [ 'caltypes', 'calparams' ] ),
        isCalType: {
            get() { return this.calparams.calType },
            set(item) { this.upParamsCaltype(item) }
        },
    },
    methods: {
        ...mapMutations( 'calendar', [ 'upParamsCaltype' ] ),
    }
}
</script>

<style>

</style>