<template>
    <v-app>
        <MainMenu />
        <MainAppBar />
        <v-main>
            <v-container fluid class="search-container">
                <v-row class="mx-0 my-1">
                    <v-col class="pl-0 py-0" cols="12" md="2">
                        <SearchEventMenuLeft />
                    </v-col>
                    <v-col class="pa-0" cols="12" md="10">
                        <SearchEventDataTable />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import MainMenu from '@/components/MainMenu'
import MainAppBar from '@/components/MainAppBar'
import SearchEventMenuLeft from '@/components/searchevent/Searcheventmenuleft'
import SearchEventDataTable from '@/components/searchevent/Searcheventdatatable'

export default {
    name: 'Searchevent',
    components: {
        MainMenu,
        MainAppBar,
        SearchEventMenuLeft,
        SearchEventDataTable
    },
}
</script>

<style>

</style>