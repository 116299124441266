<template>
   <v-text-field
        label="Email"
        v-model="isOrgEmail"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'organisation', [ 'orgparams' ] ),
        isOrgEmail: {
            get() { return this.orgparams.email },
            set(item) { this.upParamsEmail(item) }
        }
    },
    methods: {
        ...mapMutations( 'organisation', [ 'upParamsEmail' ] )
    }
}
</script>

<style>

</style>