<template>
   <v-text-field
        label="Link to Organisation Linkedin"
        v-model="isLkLinkedin"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'organisation', [ 'orgparams' ] ),
        isLkLinkedin: {
            get() { return this.orgparams.lkLinkedin },
            set(item) { this.upParamsLklinkedin(item) }
        }
    },
    methods: {
        ...mapMutations( 'organisation', [ 'upParamsLklinkedin' ] )
    }
}
</script>

<style>

</style>