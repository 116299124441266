<template>
   <v-text-field
        label="Calendar Name"
        v-model="isCalName"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'calendar', [ 'calparams' ] ),
        isCalName: {
            get() { return this.calparams.calName },
            set(item) { this.upParamsCalname(item) }
        }
    },
    methods: {
        ...mapMutations( 'calendar', [ 'upParamsCalname' ] )
    }
}
</script>

<style>

</style>