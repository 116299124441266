<template>
  <v-data-table
        :headers="headers"
        :items="getEvents"
        :items-per-page="50"
        item-key="sptName"
    >
        <template v-slot:[`item.year`]="{ item }">
            <v-chip class="year-chip" :color="getColor(item.year)" dark>{{ item.year }}</v-chip>
        </template>
        
        <template v-slot:[`item.period`]="{ item }">
            <div class="totperiod">
                <div class="iniperiod">
                    <div>{{item.dtBegin.slice(8,10)}} - {{getMonth(item.dtBegin.slice(5,7))}}</div>
                </div>
                <div class="endperiod">
                    <div>{{item.dtEnd.slice(8,10)}} - {{getMonth(item.dtEnd.slice(5,7))}}</div>
                </div>
            </div>
        </template>

        <template v-slot:[`item.calName`]="{ item }">
            <strong>{{item.calName}}</strong>
            <br>{{item.title.substr(0, 40) + '...'}}
        </template>

        <template v-slot:[`item.city`]="{ item }">
            <a class="city-link" :href="'https://www.google.de/maps/place/'+item.city" target="_blank">
                <v-icon right small>mdi-map-marker</v-icon>
                {{item.city}}
            </a>
        </template>

        <template v-slot:[`item.country`]="{ item }">
            <v-img
                class="country-flag"
                :src="require('@/assets/flags/'+item.countryFlag.toLowerCase()+'.png')"
                aspect-ratio="0.7"
                contain
                max-height="70"
                :title="item.countryName"
            ></v-img>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon @click="delEvent(item.id)">mdi-delete</v-icon>
        </template>

    </v-data-table>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Searchevent',
    data () {
        return {
            drawerLeft: false,
            headers: [
                { text: 'Year', align: 'center', sortable: false, value: 'year' },
                { text: 'Period', align: 'center', sortable: false, fixed: true, value: 'period' },
                { text: 'Event Type / Event Name', align: 'left', sortable: false, value: 'calName' },
                { text: 'Sport', align: 'center', sortable: false, value: 'sptName' },
                { text: 'City', align: 'center', sortable: false, value: 'city' },
                { text: 'Country', align: 'center', sortable: false, value: 'country' },
                { text: 'Organisation', align: 'center', sortable: false, value: 'acronym' },
                { text: 'Event Type', align: 'center', sortable: false, value: 'eventType' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            expanded: [],
            singleExpand: true,
        }
    },
    mounted() {
        this.fetchEventsByUser(this.$auth.user.sub.slice(6))
    },
    computed: {
        ...mapGetters( 'event', [ 'getEvents' ] )
    },
    methods: {
        ...mapActions( 'event', [ 'fetchEventsByUser', 'delEvent' ]),
        getColor(dtBegin) {
            if (Number(dtBegin)%2 == 0) return 'light-blue darken-1'            
            else return 'light-blue darken-3'
        },
        getMonth(datep) {
            switch (datep) {
                case '01': return 'Jan'
                case '02': return 'Feb'
                case '03': return 'Mar'
                case '04': return 'Apr'
                case '05': return 'May'
                case '06': return 'Jun'
                case '07': return 'Jul'
                case '08': return 'Aug'
                case '09': return 'Sep'
                case '10': return 'Oct'
                case '11': return 'Nov'
                case '12': return 'Dec'
            }
        }
    }
}
</script>

<style>

</style>