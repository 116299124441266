<template>
  <v-app>
    <MainMenu />
    <MainAppBar />
    <v-main>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="2">
                    <img
                    :src="$auth.user.picture"
                    alt="User's profile picture"
                    class="rounded-circle img-fluid profile-picture"
                    />
                </v-col>
                <v-col cols="12" md="10">
                    <h2>{{ $auth.user.name }}</h2>
                    <p class="lead text-muted">{{ $auth.user.email }}</p>
                    <p class="lead text-muted">{{ $auth.user.sub.slice(6) }}</p>
                    <p class="lead text-muted">{{ teste }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    {{ JSON.stringify($auth.user, null, 2) }}
                </v-col>
            </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import MainMenu from '@/components/MainMenu'
import MainAppBar from '@/components/MainAppBar'

export default {
    name: 'Userprofile',
    components: {
        MainMenu,
        MainAppBar
    },
    data() {
        return {
            teste: '',
        }
    },
    mounted() {
        this.teste = this.$auth.user.sub
    }

}
</script>

<style>

</style>