<template>
  <v-row class="home-hd-toolbar ma-0">
        <span class="sm-hd_toolbar">
            <v-btn class="sm-btn" icon color="white"><v-icon>fab fa-facebook-f</v-icon></v-btn>
            <v-btn class="sm-btn" icon color="white"><v-icon>fab fa-twitter</v-icon></v-btn>
            <v-btn class="sm-btn" icon color="white"><v-icon>fab fa-youtube</v-icon></v-btn>
            <v-btn class="sm-btn" icon color="white"><v-icon>fab fa-instagram</v-icon></v-btn>
            <v-btn class="sm-btn" icon color="white"><v-icon>fab fa-linkedin-in</v-icon></v-btn>
        </span>
        <v-spacer></v-spacer>
        
        <span class="menu-hd-toolbar">
            <v-btn class="tool-btn" text small color="white" @click="sptfLogin" v-if="!$auth.isAuthenticated">Log In</v-btn>
            <v-btn class="tool-btn" text small color="white" @click="sptfSignup" v-if="!$auth.isAuthenticated">Sign Up</v-btn>
            <MainUserMenu />
        </span>
        
  </v-row>
</template>

<script>
import MainUserMenu from '@/components/MainUserMenu'

export default {
    name: 'Homeheadertoolbar',
    components: {
        MainUserMenu,
    },
    methods: {
        sptfLogin(){
            this.$auth.loginWithRedirect();
            console.log('we are in auth0Login');
        },
        sptfSignup() {
            this.$auth.loginWithRedirect({
                screen_hint: "signup",
            });
        },
    },

}
</script>

<style>

    .home-hd-toolbar {
        flex: 0 0 auto !important;
        height: 40px;
        width: 100%;
    }

    .home-hd-toolbar > span {
        display: flex;
        flex-flow: row;
        align-items: center;
    }

    .sm-btn {
        margin: 0 8px;
    }

    .tool-btn {
        padding: 0 16px !important;
    }

</style>