<template>
   <v-text-field
        label="Phone"
        v-model="isOrgPhone"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'organisation', [ 'orgparams' ] ),
        isOrgPhone: {
            get() { return this.orgparams.phone },
            set(item) { this.upParamsPhone(item) }
        }
    },
    methods: {
        ...mapMutations( 'organisation', [ 'upParamsPhone' ] )
    }
}
</script>

<style>

</style>