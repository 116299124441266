<template>
    <v-toolbar flat>
        <v-toolbar-title>User Management</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <UserCreate />
    </v-toolbar>
</template>

<script>
import UserCreate from '@/components/user/Usercreate'

export default {
    name: 'Usertoolbar',
    components: {
        UserCreate
    }
}
</script>

<style>

</style>