<template>
    <v-menu v-model="fromDateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        >
        <template v-slot:activator="{ on }">
            <v-text-field class="fm-dt bd-no pl-4"
                placeholder="Initial Date"
                readonly
                :value="isInidate"
                v-on="on"
                solo flat dense hide-details
                prepend-icon="mdi-calendar"
                clearable
                clear-icon="mdi-close"
                @click:clear="upParamsId('')"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="isInidate"
            no-title
            @input="fromDateMenu = false"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data: () => ({
        fromDateMenu: false
    }),
    computed: {
        ...mapState( 'searchevent', [ 'searchparams' ] ),
        isInidate: {
            get() { return this.searchparams.inidate },
            set(item) { this.upParamsId(item) }
        }
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsId' ] ),
    },
}
</script>

<style>

    .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
        background-color: transparent;        
    }

    .v-text-field--solo {
        border-radius: 0;
    }

    .v-application--is-ltr .v-input__prepend-outer {
        margin-right: 0;
    }

    input {
        font-size: 14px !important;
    }

</style>