<template>
    <v-app>
        <MainMenu />
        <MainAppBar />
        <v-main>
            <v-container fluid>
                <v-row class="mx-0 my-1">
                    <v-col class="pa-0" cols="12" md="12">
                        <OrgToolBar />
                        <OrgDataTable />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import MainMenu from '@/components/MainMenu'
import MainAppBar from '@/components/MainAppBar'
import OrgToolBar from '@/components/organisation/Orgtoolbar'
import OrgDataTable from '@/components/organisation/Orgdatatable'

export default {
    name: 'OrgManagement',
    components: {
        MainMenu,
        MainAppBar,
        OrgToolBar,
        OrgDataTable
    },
}
</script>

<style>

</style>