<template>
    <v-autocomplete
        v-model="isHostCountry"
        :items="countries"
        item-value="id"
        item-text="countryName"
        filled
        hide-details
        label="Country"
        append-icon="mdi-chevron-down"
        @change="onChange"
    ></v-autocomplete>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    mounted() {
        this.fetchCountries()
    },
    computed: {
        ...mapState( 'event', [ 'eventparams', 'countries' ] ),
        isHostCountry: {
            get() { return this.eventparams.ctrId },
            set(item) { this.upParamsCountry(item) }
        }
    },
    methods: {
        ...mapActions( 'event', [ 'fetchCountries', 'fetchCities' ] ),
        ...mapMutations( 'event', [ 'upParamsCountry', 'upParamsCountryName', 'upParamsCountryFlag' ] ),
        onChange(item) {
            let ctritem = this.countries.find(ctritem => ctritem.id === item);
            this.upParamsCountryName(ctritem.countryName)
            this.upParamsCountryFlag(ctritem.countryFlag)
        }
    }
}
</script>

<style>

</style>