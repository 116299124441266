<template>
    <v-menu v-model="fromDateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        >
        <template v-slot:activator="{ on }">
            <v-text-field class="fm-dt-ini bd-rad"
                placeholder="Initial Date"
                readonly
                :value="isInidate"
                v-on="on"
                outlined dense hide-details
                append-icon="mdi-calendar"
                clearable
                clear-icon="mdi-close"
                @click:clear="upParamsId('')"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="isInidate"
            no-title
            @input="fromDateMenu = false"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data: () => ({
        fromDateMenu: false
    }),
    computed: {
        ...mapState( 'searchevent', [ 'searchparams' ] ),
        isInidate: {
            get() { return this.searchparams.inidate },
            set(item) { this.upParamsId(item) }
        }
    },
    mounted() {      
        this.iniInidt()
    },
    methods: {
        ...mapMutations( 'searchevent', [ 'upParamsId' ] ),
        iniInidt() {
            this.isInidate = new Date().toISOString().substr(0, 10)
        }
    },
}
</script>

<style>

</style>