<template>
    <v-autocomplete
        v-model="isSptOrgUp"
        :items="orgsUp"
        item-text="orgAcronymName"
        item-value="id"
        filled
        hide-details
        label="Organisation Up"
        @change="onChange"
    ></v-autocomplete>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    mounted() {
        this.fetchOrgs()
    },
    computed: {
        ...mapState( 'sport', [ 'orgsUp', 'sportparams' ] ),
        isSptOrgUp: {
            get() { return this.sportparams.orgUp },
            set(item) { this.upParamsSptorgup(item) }
        }
    },
    methods: {
        ...mapActions( 'sport', [ 'fetchOrgs' ] ),
        ...mapMutations( 'sport', [ 'upParamsSptorgup', 'upParamsSptorgacronymname', 'upParamsSptorgacronym' ] ),
        onChange(item) {
            let orgitem = this.orgsUp.find(orgitem => orgitem.id === item);
            this.upParamsSptorgacronymname(orgitem.orgAcronymName)
            this.upParamsSptorgacronym(orgitem.acronym)
        }
    }
}
</script>

<style>

</style>