<template>
    <v-app>
        <MainMenu />
        <MainAppBar />
        <v-main>
            <v-container fluid>
                <v-row class="mx-0 my-1">
                    <v-col class="pa-0" cols="12" md="12">
                        <CalendarToolBar />
                        <CalendarDataTable />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import MainMenu from '@/components/MainMenu'
import MainAppBar from '@/components/MainAppBar'
import CalendarToolBar from '@/components/calendar/Calendartoolbar'
import CalendarDataTable from '@/components/calendar/Calendardatatable'

export default {
    name: 'CalendarManagement',
    components: {
        MainMenu,
        MainAppBar,
        CalendarToolBar,
        CalendarDataTable
    },
}
</script>

<style>

</style>