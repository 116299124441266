<template>
   <v-text-field
        label="Link to Twitter Streaming"
        v-model="isLkStreamTw"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'event', [ 'eventparams' ] ),
        isLkStreamTw: {
            get() { return this.eventparams.lkStreamTw },
            set(item) { this.upParamsLkStreamTw(item) }
        }
    },
    methods: {
        ...mapMutations( 'event', [ 'upParamsLkStreamTw' ] )
    }
}
</script>

<style>

</style>