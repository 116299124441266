<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="light-blue darken-3 py-2" @click="clearUserData" rounded dark>
                <v-icon left>mdi-plus</v-icon>Create
            </v-btn>
        </template>
        <v-card>
            <v-form @submit.prevent="usrAddSubmit">
                <v-card-title class="light-blue darken-3" dark>
                    <span class="headline">Create a New User</span>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pa-8">
                    <v-row>
                        <v-col class="pr-8" cols="12" md="8"> 
                            <v-row>
                                <v-col><UserFirstName /></v-col>
                                <v-col><UserMiddleName /></v-col>
                                <v-col><UserLastName /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><UserNickname /></v-col>
                                <v-col><UserGenre /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><UserDateBirth /></v-col>
                                <v-col><UserPhone /></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="9"><UserAdrStreetComp /></v-col>
                                <v-col cols="12" md="3"><UserAdrPostCode /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><UserAdrCity /></v-col>
                                <v-col><UserAdrCountry /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><UserOrgUp /></v-col>
                                <v-col><UserOrgReport /></v-col>
                                <v-col><UserOrgPosition /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><UserLkPersonalWebsite /></v-col>
                                <v-col><UserLkLinkedin /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><UserLkFacebook /></v-col>
                                <v-col><UserLkYoutube /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><UserLkTwitter /></v-col>
                                <v-col><UserLkInstagran /></v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pl-8" cols="12" md="4">
                            <!--
                            <v-row>
                                <v-col><UserPhoto /></v-col>
                            </v-row>
                            -->
                            <v-row>
                                <v-col><UserAvatar /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><UserRole /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><UserEmail /></v-col>
                            </v-row>
                            <v-row>
                                <v-col><UserPassword /></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <p class="py-0 my-0">Owner</p>
                                    <h3>{{ $auth.user.name }}</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-card-actions class="px-0">
                                        <v-btn type="submit" color="light-blue darken-3 px-4" rounded dark @click="dialog = false">
                                            Create
                                        </v-btn>
                                    </v-card-actions>    
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-form>
            {{userparams}}
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import UserFirstName from './Usernamefirst'
import UserMiddleName from './Usernamemiddle'
import UserLastName from './Usernamelast'
import UserDateBirth from './Userdatebirth'
import UserGenre from './Usergenre'
import UserAdrStreetComp from './Useradrstreetcomp'
import UserAdrPostCode from './Useradrpostcode'
import UserAdrCity from './Useradrcity'
import UserAdrCountry from './Useradrcountry'
import UserOrgUp from './Userorgup'
import UserOrgReport from './Userorgreport'
import UserOrgPosition from './Userorgposition'
import UserLkPersonalWebsite from './Userlkpersonalwebsite'
import UserLkLinkedin from './Userlklinkedin'
import UserLkFacebook from './Userlkfacebook'
import UserLkYoutube from './Userlkyoutube'
import UserLkTwitter from './Userlktwitter'
import UserLkInstagran from './Userlkinstagran'
import UserPhoto from './Userphoto'
import UserNickname from './Usernamenick'
import UserRole from './Userrole'
import UserEmail from './Useremail'
import UserPhone from './Userphone'
import UserPassword from './Userpassword'
import UserAvatar from './Useravatar'

export default {
    components: {
        UserFirstName,
        UserMiddleName,
        UserLastName,
        UserDateBirth,
        UserGenre,
        UserAdrStreetComp,
        UserAdrPostCode,
        UserAdrCity,
        UserAdrCountry,
        UserOrgUp,
        UserOrgReport,
        UserOrgPosition,
        UserLkPersonalWebsite,
        UserLkLinkedin,
        UserLkFacebook,
        UserLkYoutube,
        UserLkTwitter,
        UserLkInstagran,
        UserPhoto,
        UserNickname,
        UserRole,
        UserEmail,
        UserPhone,
        UserPassword,
        UserAvatar
    },
    data: () => ({
        dialog: false
    }),
    mounted() {
        this.upParamsInsertedby(this.$auth.user.sub.slice(6)),
        this.upParamsUpdatedby(this.$auth.user.sub.slice(6))
    },
    computed: {
        ...mapState( 'user', [ 'userparams' ] )
    },
    methods: {
        ...mapActions( 'user', [ 'addUser', 'addUserPhoto' ] ),
        ...mapMutations( 'user', [ 'upParamsInsertedby', 'upParamsUpdatedby' ] ),
        usrAddSubmit() {
            this.addUser(this.userparams)
            //this.addUser(this.userparams)
            //this.addUserPhoto(this.userparams.photo)
            /*
            if (this.userparams.photo === {}) {
                this.addUserPhoto(data)
                    .then(this.addUser(this.userparams))
            } else {
                this.addUser(this.userparams)
            }*/
            
        },
        clearUserData() {/*
            this.userparams.photo = {},
            this.userparams.nickName = '',
            this.userparams.email = '',
            this.userparams.phone = '',
            this.userparams.role = '',
            this.userparams.password = '',
            this.userparams.firstName = '',
            this.userparams.middleName = '',
            this.userparams.lastName = '',
            this.userparams.dateBirth = '',
            this.userparams.genre = '',
            this.userparams.adrStreetComp = '',
            this.userparams.adrPostalCode = '',
            this.userparams.adrCity = '',
            this.userparams.adrCountry = '',
            this.userparams.orgUp = '',
            this.userparams.orgReport = '',
            this.userparams.orgPosition = '',
            this.userparams.lkPersonalWebsite = '',
            this.userparams.lkLinkedin = '',
            this.userparams.lkFacebook = '',
            this.userparams.lkYoutube = '',
            this.userparams.lkTwitter = '',
            this.userparams.lkInstagran = ''
        */}
    },
}
</script>

<style>
    .headline {
        color: white;
    }
    .collor-picker-new {
        max-width: none !important;
    }
</style>