<template>
    <v-btn 
        class="fm-btn"
        type="submit"
        block
        rounded
        depressed
        color="primary"
        append-icon="mdi-calendar-search"
    >
        Search <v-icon right>mdi-calendar-search</v-icon>
    </v-btn>
</template>

<script>
export default {

}
</script>

<style>

</style>