<template>
    <v-menu bottom min-width="200px" rounded offset-y v-if="$auth.isAuthenticated">
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar size="32px">
              <img
                :src="$auth.user.picture"
                alt="User's profile picture"
                class="rounded-circle img-fluid profile-picture"
                />
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar size="72px">
                <img
                :src="$auth.user.picture"
                alt="User's profile picture"
                class="rounded-circle img-fluid profile-picture"
                />
              </v-avatar>
              <h3 class="my-1">{{ $auth.user.name }}</h3>
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text to="/profile" v-if="$auth.isAuthenticated">
                PROFILE
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text @click="sptfLogout" v-if="$auth.isAuthenticated">
                LOGOUT
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
</template>

<script>
export default {
    name: 'MainUserMenu',
    methods: {
        sptfLogout() {
            this.$auth.logout();
            this.$router.push({ path: "/" });
            console.log('logging out')
        }
    }
}
</script>

<style>

</style>