<template>
   <v-text-field
        label="Last Name"
        v-model="isLastName"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'user', [ 'userparams' ] ),
        isLastName: {
            get() { return this.userparams.family_name },
            set(item) { 
                this.upParamsLastName(item) 
                let forname = {field: "last", val: item}
                this.upParamsName(forname)
            }
        }
    },
    methods: {
        ...mapMutations( 'user', [ 'upParamsLastName', 'upParamsName' ] )
    }
}
</script>

<style>

</style>