<template>
  <v-data-table
        :headers="headers"
        :items="getCalendars"
        :items-per-page="50"
        item-key="calName"
    >
        <template v-slot:[`item.color`]="{ item }">
            <v-chip :color="item.color" dark>
                {{ item.color }}
            </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <CalendarUpdate :calid="item.id"/>
            <v-icon @click="delCalendar(item.id)">mdi-delete</v-icon>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import CalendarUpdate from '@/components/calendar/Calendarupdate'

export default {
    name: 'Calendardatatable',
    components: {
        CalendarUpdate
    },
    data () {
        return {
            drawerLeft: false,
            headers: [
                { text: 'Calendar Name', align: 'left', sortable: false, value: 'calName' },
                { text: 'Type', align: 'center', sortable: false, value: 'calType' },
                { text: 'Organisation', align: 'left', sortable: false, value: 'orgAcronymName' },
                { text: 'Organisation Role', align: 'center', sortable: false, value: 'orgRole' },
                { text: 'User Role', align: 'center', sortable: false, value: 'usrRole' },
                { text: 'Color', align: 'center', sortable: false, value: 'color' },
                { text: 'Actions', align: 'center', value: 'actions', sortable: false },
            ],
            expanded: [],
            singleExpand: true,
        }
    },
    mounted() {
        this.fetchCalByUser(this.$auth.user.sub.slice(6))
    },
    computed: {
        ...mapGetters( 'calendar', [ 'getCalendars' ] )
    },
    methods: {
        ...mapActions( 'calendar', [ 'fetchCalByUser', 'delCalendar' ]),
    }
}
</script>

<style>

</style>