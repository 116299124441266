<template>
    <v-container class="pa-0" fluid>
        <v-radio-group class="pa-0 ma-0" v-model="isSportIndivTeam">
            <template v-slot:label>
                <div><strong>Individual/Team</strong></div>
            </template>
            <v-radio
                v-for="item in indivteams"
                :key="item"
                :label="item"
                :value="item"
            ></v-radio>
        </v-radio-group>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'sport', [ 'indivteams', 'sportparams' ] ),
        isSportIndivTeam: {
            get() { return this.sportparams.indivTeam },
            set(item) { this.upParamsSptindivteam(item) }
        }
    },
    methods: {
        ...mapMutations( 'sport', [ 'upParamsSptindivteam' ] )
    }
}
</script>

<style>

</style>