<template>
   <v-text-field
        label="Password"
        v-model="isPassword"
        filled
        hide-details
    ></v-text-field>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
        ...mapState( 'user', [ 'userparams' ] ),
        isPassword: {
            get() { return this.userparams.password },
            set(item) { this.upParamsPassword(item) }
        }
    },
    methods: {
        ...mapMutations( 'user', [ 'upParamsPassword' ] )
    }
}
</script>

<style>

</style>