<template>
    <v-app>
        <MainMenu />
        <MainAppBar />
        <v-main>
            <v-container fluid>
                <v-row class="mx-0 my-1">
                    <v-col class="pa-0" cols="12" md="12">
                        <UserToolBar />
                        <UserDataTable />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import MainMenu from '@/components/MainMenu'
import MainAppBar from '@/components/MainAppBar'
import UserToolBar from '@/components/user/Usertoolbar'
import UserDataTable from '@/components/user/Userdatatable'

export default {
    name: 'OrgManagement',
    components: {
        MainMenu,
        MainAppBar,
        UserToolBar,
        UserDataTable
    },
    mounted() {
        this.getUserData()
    },
    computed: {
        ...mapState( 'user', [ 'userparams' ] )
    },
    methods: {
        ...mapActions( 'user', [ 'fetchUsers' ]),
        async getUserData() {
            // Get the access token from the auth wrapper
            const accessToken = await this.$auth.getTokenSilently();
            // Pass the access token to the getUsers service
            this.fetchUsers(accessToken)
        }
    }
}
</script>

<style>

</style>