<template>
    <v-navigation-drawer
        v-model="drawer"
        :color="color"
        :mini-variant.sync="miniVariant"
        app
        dark
        permanent
        v-if="$auth.isAuthenticated"
    >
        <v-list dense nav class="py-1">
            <v-list-item @click.stop="miniVariant = !miniVariant">
                <v-list-item-icon class="mx-0 my-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon height="24" width="24" v-bind="attrs" v-on="on">
                                <v-icon>mdi-sort-variant</v-icon>
                            </v-btn>
                        </template>
                        <span>Open/Close Menu</span>
                    </v-tooltip>
                </v-list-item-icon>
            </v-list-item>
            <v-divider class="mb-2"></v-divider>
            <v-list-item v-for="item in itemsdsh" :key="item.title" link :to="item.link">
                <v-list-item-icon class="mr-4">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">{{ item.icon }}</v-icon>
                        </template>
                        <span>{{ item.title }}</span>
                    </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mb-2"></v-divider>
            <v-list-item v-for="item in itemscal" :key="item.title" link :to="item.link">
                <v-list-item-icon class="mr-4">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">{{ item.icon }}</v-icon>
                        </template>
                        <span>{{ item.title }}</span>
                    </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-list-item v-for="item in itemsspo" :key="item.title" link :to="item.link">
                <v-list-item-icon class="mr-4">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">{{ item.icon }}</v-icon>
                        </template>
                        <span>{{ item.title }}</span>
                    </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <template v-slot:append>
            <v-list dense nav class="py-1">
                <v-list-item>
                    <v-list-item-icon class="mr-4">
                        <v-icon>mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Settings</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'MainMenu',
    data () {
        return {
            drawer: null,
            itemsdsh: [
                { title: 'Dashboard', icon: 'mdi-view-dashboard', link: '/dashboard' },
            ],
            itemscal: [
                { title: 'View Calendar', icon: 'mdi-calendar-search', link: '/viewcalendar'  },
                { title: 'Calendar', icon: 'mdi-calendar-month', link: '/calendar'  },
                { title: 'Search Event', icon: 'mdi-stadium', link: '/searchevent'  },
                { title: 'Event', icon: 'mdi-stadium-variant', link: '/event'  },
            ],
            itemsspo: [
                { title: 'Athlete', icon: 'mdi-shield-account', link: '/'  },
                { title: 'Team', icon: 'mdi-shield-half-full', link: '/'  },
                { title: 'Delegation', icon: 'mdi-flag-variant', link: '/'  },
                { title: 'Organisation', icon: 'mdi-web', link: '/organisation'  },
                { title: 'Sport', icon: 'mdi-soccer-field', link: '/sport'  },
                { title: 'User', icon: 'mdi-account-group', link: '/user'  }
            ],
            color: 'light-blue darken-3',
            miniVariant: true,
        }
    }
}
</script>

<style>

</style>